<div class="fwt-product-card-cart">
    <div class="row">

        <ng-container>
            <div class="col-6 col-lg-4 col-xl-3 px-2" *ngFor="let item of productListItem?.items">
                <div class="fwt-product-card all-fwt-product-card mb-3">

                    <div class="product-card-text">
                        <div class="product-card-img" [routerLink]="'/shop/' + item.slug">
                            <img src="{{item.thumbnail}}" alt="">
                        </div>
                        <h6 class="pt-2 pb-1">{{item.name}}</h6>
                        <select name="" id="" [(ngModel)]="item.selectedVariant">
                            <option *ngFor="let prc of item.variants" [ngValue]="prc">{{prc.name}}
                                {{prc.unit.symbol}} </option>
                            <!-- <option value="">250 gms</option>
                                    <option value="">1 kg</option>
                                    <option value="">2 kg</option> -->
                        </select>
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="pb-0">Rs. {{item?.selectedVariant?.price}}</p>
                            <ng-container *ngIf="!item.selectedVariant?.cartInfo?.qty; else templateA">
                                <a class="add-btn" (click)="updateQuantity(item , item.selectedVariant)">add</a>
                            </ng-container>
                            <ng-template #templateA>

                                <div class="qty-input">
                                    <button class="qty-count qty-count--minus" data-action="minus"
                                        (click)="decreaseQuantity(item,item.selectedVariant)" type="button">-</button>
                                    <span class="product-qty">{{item.selectedVariant.cartInfo.qty}}</span>
                                    <button class="qty-count qty-count--add" data-action="add"
                                        (click)="addQuntity(item,item.selectedVariant)" type="button">+</button>
                                </div>
                            </ng-template>


                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div>