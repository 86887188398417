import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ReviewsModel,
  ReviewsRequestModel,
} from '../reviews/model/reviews.model';
import { ReviewsService } from '../reviews/services/reviews.service';
import { ProductModel } from '../../product/model/product';
import { ProductDetailsModel } from '../../product/model/product-details.model';
import { StarRatingComponent } from '../start-rating/star-rating/star-rating.component';
import { SessionsStorageService } from '../../../core/services/storage.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PaginantorComponent } from '../paginantor/paginantor.component';
import { AddReviewComponent } from './add-review/add-review.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-review',
  standalone: true,
  templateUrl: './team-review.component.html',
  styleUrl: './team-review.component.scss',
  imports: [CommonModule, StarRatingComponent, PaginantorComponent],
})
export class TeamReviewComponent implements OnInit {
  @Input() reviews!: ProductDetailsModel;
  reviewsList!: ReviewsModel;
  mobileReviewList!: ReviewsModel;
  tokenAvaialableCheck!: string;
  reviewsModelObject: ReviewsRequestModel = {
    page: 1,
    limit: 2,
    sortBy: 'id',
  };
  tokenCheck!: string;
  userFullName!: string;
  ngOnInit() {
    this.getReviewsList();
    this.getTokenForActiveUser();
    this.getUserDetails();
  }
  constructor(
    private reviewsService: ReviewsService,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router
  ) {}

  getPageCount() {
    return Math.ceil(this.reviewsList?.total / this.reviewsModelObject.limit);
  }

  totalPages: number = 1;
  currentPage: number = 1;

  onPageChange(page: number) {
    this.currentPage = page;
    this.reviewsModelObject.page = this.currentPage;
    this.reviewsService.all(this.reviewsModelObject).subscribe((resp) => {
      this.reviewsList = resp;
    });
  }
  getTokenForActiveUser() {
    this.tokenAvaialableCheck = this.authenticationService.getToken();
  }
  getReviewsList() {
    this.reviewsService.all(this.reviewsModelObject).subscribe((resp) => {
      this.reviewsList = resp;
      this.mobileReviewList = resp;
      this.totalPages = this.getPageCount();
      this.cd.detectChanges();
    });
  }

  reviewListLoadMore() {
    this.reviewsModelObject.page = this.currentPage++;
    this.reviewsService
      .all(this.reviewsModelObject)
      .subscribe((resp: ReviewsModel) => {
        const newReviews = resp.items || [];
        this.mobileReviewList.items = [
          ...this.mobileReviewList.items,
          ...newReviews,
        ];
        this.reviewsList.items = this.mobileReviewList.items;
      });
  }
  getUserDetails() {
    this.tokenCheck = this.authenticationService.getToken();
    this.userFullName =
      this.authenticationService.getUserFullNameTOLocalStorage();
  }

  addReview(productId: number) {
    if (this.tokenCheck) {
      const modalRef = this.modalService.open(AddReviewComponent, {
        centered: true,
        size: 'confirm-size',
      });
      modalRef.componentInstance.userFullName = this.userFullName;
      modalRef.componentInstance.productId = productId;
      modalRef.result.then(() => {
        this.getReviewsList();
      });
    }
    this.router.navigate(['/auth/login']);
  }
}
