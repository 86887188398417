<app-full-page-layout>

    <main>
        <section class="hero-abp">
            <div class="container-lg px-4 px-sm-2">
                <div class="row">
                    <div class="col-lg-6">
                        <h1 class="section-title">Growing Goodness <br>
                            <span class="text-malachite">Anywhere. Everywhere</span>
                        </h1>
                    </div>
                    <div class="col-lg-6">
                        <p>GoPurple is raising the standard of farming with advanced-stage Fogponics technology. We are
                            the
                            world’s first commercial smart indoor vertical farm, putting in line Fogponics technology
                            for
                            efficiently growing crops and preparing stages for indoor farming. We are innovators in the
                            vertical farming space, not just with fogponics but with our process orientation toward
                            controlled environment agriculture.</p>
                        <div class="hero-img-abp">
                            <img src="assets/images/about-banner-elem.webp" class="h-100 w-100" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="about about-fogponics">
            <div class="container px-4 px-sm-2 px-lg-25">
                <div class="row flex-lg-row align-items-lg-center justify-content-center gy-5 mx-0 animation aos-init" data-aos="fade-up">
                    <div class="col-lg-5 px-0 px-md-2">
                        <h2 class="section-title text-green"><span>About Fogponics</span></h2>
                        <p class="section-desc pt-4 pt-lg-5 mb-3 mb-md-4">Fogponics is a soilless farming method where
                            the roots
                            of the plants are sprayed directly with nutrient-rich fog or timed cycles in a
                            self-contained environment (BEDS).</p>
                        <p class="section-desc">This fine mist of water and essential nutrients envelops the root
                            system, ensuring a highly efficient and precise absorption process. By not using soil or
                            other growing mediums like coco peat or peat moss, Fogponics optimises nutrient intake,
                            promoting faster growth and healthier crops.</p>
                        <h4>One of the key advantages of Fogponics is its ability to provide unparalleled precision and
                            control over the plant's growing environment.</h4>
                    </div>
                    <div class="d-none d-lg-block col-lg-1"></div>
                    <div class="col-12 col-lg-4 px-0 px-md-2">
                        <img src="assets/images/about-fogponics.webp" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </section>

        <!-- accordian component start -->
        <app-about-us-accordian></app-about-us-accordian>
        <!-- accordian component end -->

        <section class="our-impact">
            <div class="container px-4 px-sm-2">
                <div class="title-group text-center animation aos-init" data-aos="fade-up">
                    <h2 class="section-title d-inline-block text-light-green"><span>Our Impact</span></h2>
                </div>
                <div class="grow-more-wrapper animation aos-init" data-aos="fade-up">
                    <div class="row mx-0 justify-content-center gy-1 mx-0">
                        <div class="col-lg-4 px-0 px-lg-2 px-lg-0">
                            <div class="impact-card">
                                <div class="impact-content">
                                    <h4>Economic Sustainability</h4>
                                    <p>Optimised land use and higher crop yields contribute to economic benefits for
                                        farmers
                                        and consumers.</p>
                                </div>
                                <div class="impact-img">
                                    <img src="assets/images/impact-1.webp" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 px-0 px-lg-2 px-lg-0">
                            <div class="impact-card ">
                                <div class="impact-content">
                                    <h4>A Greener Tomorrow</h4>
                                     <p>Optimised land use and higher crop yields contribute to economic benefits for
                                        farmers
                                        and consumers.</p>
                                </div>
                                <div class="impact-img">
                                    <img src="assets/images/impact-2.webp" alt="">

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 px-0 px-lg-2 px-lg-0">
                            <div class="impact-card ">
                                <div class="impact-content">
                                    <h4>Year-Round Food Security</h4>
                                     <p>Optimised land use and higher crop yields contribute to economic benefits for
                                        farmers
                                        and consumers.</p>
                                </div>
                                <div class="impact-img">
                                    <img src="assets/images/impact-1.webp" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-1 gy-1">
                        <div class="col-lg-6 px-0 px-lg-2 px-lg-0">
                            <div class="impact-card impact-lg-card ">
                                <div class="impact-content">
                                    <h4>local <br> empowerment</h4>
                                     <p>Optimised land use and higher crop yields contribute to economic benefits for
                                        farmers
                                        and consumers.</p>
                                </div>
                                <div class="impact-img">
                                    <img src="assets/images/impact-4.webp" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 px-0 px-lg-2 px-lg-0">
                            <div class="impact-card impact-lg-card ">
                                <div class="impact-content">
                                    <h4>nature <br> presentation</h4>
                                     <p>Optimised land use and higher crop yields contribute to economic benefits for
                                        farmers
                                        and consumers.</p>
                                </div>
                                <div class="impact-img">
                                    <img src="assets/images/impact-5.webp" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section></section>
        <section class="about-content">
            <div class="container px-4 px-sm-2">
                <p>GoPurple, launched in 2018 by GreenGo Agro Technologies Pvt Ltd, was created in
                    response to the
                    increasing challenges of food shortages and climate change, which severely impact farmers and
                    consumers due to a heavy dependence on imports. Our principle is to efficiently utilize natural
                    resources while employing advanced farming technologies, aiming to improve the future of agriculture
                    and increase crop yields.</p>
                <div class="mid-img text-center w-100">
                    <img src="assets/images/mid-img-1.webp" class="img-fluid" alt="">
                </div>
            </div>
        </section>
        <section class="climate">
            <div class="container-lg px-4 px-sm-2">
                <div class="row gy-3 animation aos-init" data-aos="fade-up">
                    <div class="col-lg-6">
                        <h2 class="section-title mb-0">You Can Too Make <br class="d-none d-lg-block"> A Difference For
                            <br class="d-none d-lg-block">
                            <span class="text-malachite">Our Climate</span>
                        </h2>
                    </div>
                    <div class="col-lg-6">
                        <div class="climate-content">
                            <p class="mb-4 mb-lg-5">Bring home Fogponics technology and get started with products and
                                quality crops, keeping
                                sustainable practices at the top. At GoPurple, we provide end-to-end solutions to
                                customers looking to expand their farming business by setting up indoor farms adhering
                                to our franchise agreement. With us, customers will get complete access to everything
                                from equipment to training and SOPs to crop growing processes, monthly consumption, and
                                direct access to ‘the Purple Network’, an online marketplace.</p>
                            <button class="primary-btn">KNOW MORE</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-subscribe></app-subscribe>
        <section class="blog">
            <div class="container px-4 px-sm-2">
                <div class="blog-container animation aos-init" data-aos="fade-up">
                    <div class="row justify-content-between align-items-center mb-4 mx-0">
                        <div class="col-7">
                            <h2 class="section-title"><span class="text-green">GoPurple Blogs</span></h2>
                        </div>
                        <div class="col-5 text-end">
                            <button class="primary-btn purple-btn">VIEW ALL</button>
                        </div>
                    </div>
                    <div class="row gy-2 justify-content-center mx-0">
                        <div class="col-md-6 col-lg-4 px-0 px-md-2">
                            <div class="blog-card">
                                <div class="blog-details mb-3">
                                    <h5 class="mb-3 fw-bold text-green">The Future of Agriculture: Exploring Fogponic
                                        Technology</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                        ever
                                        since the 1500s, when an unknown printer.</p>
                                </div>
                                <div class="blog-img position-relative">
                                    <img src="assets/images/blog.png" class="h-100 w-100" alt="blog Image">
                                    <button class="primary-btn position-absolute">READ MORE</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 px-0 px-md-2">
                            <div class="blog-card">
                                <div class="blog-details mb-3">
                                    <h5 class="mb-3 fw-bold text-green">Water-Saving Wonders: How Fogponics Outshines
                                        Conventional Farming Practices</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard dummy txt</p>
                                </div>
                                <div class="blog-img position-relative">
                                    <img src="assets/images/blog.png" class="h-100 w-100" alt="blog Image">
                                    <button class="primary-btn position-absolute">READ MORE</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 px-0 px-md-2">
                            <div class="blog-card">
                                <div class="blog-details mb-3">
                                    <h5 class="mb-3 fw-bold text-green">Gopurple: How We Addresses Challenges Faced by
                                        Traditional Agriculture</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard dummy </p>
                                </div>
                                <div class="blog-img position-relative">
                                    <img src="assets/images/blog.png" class="h-100 w-100" alt="blog Image">
                                    <button class="primary-btn position-absolute">READ MORE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="review">
            <div class="container">
                <div class="blog-container animation aos-init" data-aos="fade-up">
                    <div class="row justify-content-between align-items-center gy-3">
                        <div class="col-lg-4">
                            <h2 class="section-title text-malachite pb-3 pb-lg-1 ">Loved By Many</h2>
                            <div class="slider-nav slick-initialized slick-slider"><div class="slick-list draggable" style="padding: 0px 50px;"><div class="slick-track" style="opacity: 1; width: 414px; transform: translate3d(0px, 0px, 0px);"><div class="slick-slide slick-current slick-center" data-slick-index="0" aria-hidden="true" style="width: 118px;"><div><div style="width: 100%; display: inline-block;">
                                    <div class="slider-thumb">
                                        <div class="thumb-img position-relative">
                                            <img src="assets/images/avatar.webp" class="img-fluid" alt="">
                                            <span class="quote position-absolute end-0 bottom-0"><img src="assets/images/quote-icon.svg" alt=""></span>
                                        </div>
                                        <h5>Jane Doe</h5>
                                    </div>
                                </div></div></div><div class="slick-slide" data-slick-index="1" aria-hidden="true" style="width: 118px;"><div><div style="width: 100%; display: inline-block;">
                                    <div class="slider-thumb">
                                        <div class="thumb-img position-relative">
                                            <img src="assets/images/avatar-2.webp" class="img-fluid" alt="">
                                            <span class="quote position-absolute end-0 bottom-0"><img src="assets/images/quote-icon.svg" alt=""></span>
                                        </div>
                                        <h5>Jane Doe</h5>
                                    </div>
                                </div></div></div><div class="slick-slide slick-center" data-slick-index="2" aria-hidden="true" style="width: 118px;"><div><div style="width: 100%; display: inline-block;">
                                    <div class="slider-thumb">
                                        <div class="thumb-img position-relative">
                                            <img src="assets/images/avatar.webp" class="img-fluid" alt="">
                                            <span class="quote position-absolute end-0 bottom-0"><img src="assets/images/quote-icon.svg" alt=""></span>
                                        </div>
                                        <h5>Jane Doe</h5>
                                    </div>
                                </div></div></div></div></div></div>
                        </div>
                        <div class="col-lg-7">
                            <div class="slider-slide slick-initialized slick-slider"><button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">Previous</button><div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 2109px;"><div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 703px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;"><div><div style="width: 100%; display: inline-block;">
                                    <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown printer took a galley of type and scrambled it to make a type specimen
                                        book.
                                    </p>
                                    <p> It has survived not only five centuries, but also the leap into electronic
                                        typesetting, remaining essentially unchanged.</p>
                                </div></div></div><div class="slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style="width: 703px; position: relative; left: -703px; top: 0px; z-index: 998; opacity: 0;"><div><div style="width: 100%; display: inline-block;">
                                    <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown printer took a galley of type and scrambled it to make a type specimen
                                        book.
                                    </p>
                                    <p> It has survived not only five centuries, but also the leap into electronic
                                        typesetting, remaining essentially unchanged.</p>
                                </div></div></div><div class="slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style="width: 703px; position: relative; left: -1406px; top: 0px; z-index: 998; opacity: 0;"><div><div style="width: 100%; display: inline-block;">
                                    <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem
                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                        unknown printer took a galley of type and scrambled it to make a type specimen
                                        book.
                                    </p>
                                    <p> It has survived not only five centuries, but also the leap into electronic
                                        typesetting, remaining essentially unchanged.</p>
                                </div></div></div></div></div><button class="slick-next slick-arrow" aria-label="Next" type="button" style="">Next</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- loved by many component start -->
        <app-loved-by-many></app-loved-by-many>
        <!-- loved by many component end -->

        <app-partner-adviser></app-partner-adviser>
   
    </main>
    <script>
        $('.slider-slide').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-slide',
            dots: true,
            arrows: false,
            centerMode: true,
            focusOnSelect: true
        });
    </script>
</app-full-page-layout>
