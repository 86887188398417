<section class="fwt-product-card-section position-relative">
    <div class="container-xl container ps-4  px-sm-2">
        <div class="fwt-product-card-content">
            <div class="title-group text-center animation">
                <h2 class="section-title d-inline-block text-light-green"><span>Products You May Like</span>
                </h2>
                <!-- <div class="row mt-5 pt-5"> -->
                <app-shop-item-list [productListItem]="productListItem"></app-shop-item-list>
                <!-- <div class="row mt-5 pt-5 d-sm-flex d-none">
                        @for(product of products;let  i = $index; track i) {
                            <ng-container>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="fwt-product-card">
                                        <div class="product-card-text">
                                            <div class="product-card-img">
                                                <img src="assets/images/{{product.image}}" alt="">
                                            </div>
                                            <h6>{{product.title}}</h6>
                                            <p>Rs. {{product.price}}</p>
                                        </div>
                                        <div class="fwt-product-card-btn">
                                            <a href="javascript:void(0)" class="primary-btn">ADD TO CART</a>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        }
                    </div>
                    <div class="fwt-card-slider d-sm-none d-block mt-5">
                        <div class="swiper mySwiper-2">
                            <div class="swiper-wrapper">
                                @for(product of products;let  i = $index; track i) {
                                    <div class="swiper-slide">
                                      <div class="fwt-product-card">
                                          <div class="product-card-text">
                                              <div class="product-card-img">
                                                <img src="assets/images/{{product.image}}" alt="">
                                              </div>
                                              <h6>{{product.title}}</h6>
                                            <p>Rs. {{product.price}}</p>
                                          </div>
                                          <div class="fwt-product-card-btn">
                                              <a href="javascript:void(0)" class="primary-btn">ADD TO CART</a>
                                          </div>
                                      </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>

</section>