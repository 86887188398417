import { Component, Input } from '@angular/core';
import { Nutrition, ProductDetailsModel } from '../../product/model/product-details.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-right-nutrients',
  standalone: true,
  imports: [ CommonModule,],
  templateUrl: './right-nutrients.component.html',
  styleUrl: './right-nutrients.component.scss',
})
export class RightNutrientsComponent {
  // nutrition!:Nutrition[];
  @Input () productDetail!:ProductDetailsModel;
}
