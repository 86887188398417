<app-full-page-layout>

<main>
    <section class="hero">
        <div class="container align-self-center px-4 px-sm-2">
            <div class="text-center">
                <h1>Taste What's Fresh</h1>
                <p>Go Smart, Go Purple</p>
            </div>
        </div>
    </section>
    <section class="vertical-farming">
        <div class="container px-4 px-sm-2">
            <div class="row justify-content-between align-items-center animation aos-init" data-aos="fade-up">
                <div class="col-md-6 col-lg-6">
                    <h2 class="section-title">We are a smart indoor <br class="d-none d-lg-block"> <span class="mb-3">vertical
                            farming</span>
                        company</h2>
                </div>
                <div class="col-md-6 col-lg-4">
                    <p class="section-desc">We passionately solve agriculture's biggest challenges, using less water
                        and land to grow more crops with cutting-edge Fogponics technology.</p>
                </div>
            </div>
            <div class="farming-counter-wrapper">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 px-0">
                        <div class="counter-card counter-card-1">
                            <p>More efficient than hydroponics</p>
                            <h3>45%</h3>
                        </div>
                    </div>
                    <div class="col-8 col-md-3 px-0">
                        <div class="counter-card counter-card-2">
                            <p>Less water usage</p>
                            <h3>97%</h3>
                        </div>
                    </div>
                    <div class="col-10 col-md-3 px-0">
                        <div class="counter-card counter-card-3">
                            <p>Less land usage</p>
                            <h3>99%</h3>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 px-0">
                        <div class="counter-card counter-card-4">
                            <p>More efficient than traditional farming</p>
                            <h3>200%</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="benefits">
        <div class="container px-4 px-sm-2">
            <div class="title-group text-center animation aos-init" data-aos="fade-up">
                <h2 class="section-title d-inline-block text-green"><span>Our Benefits</span></h2>
                <p class="section-desc mt-4 mt-lg-5">Our smart indoor vertical farm powered by cutting-edge
                    Fogponics
                    technology,
                    brings a host of
                    benefits that revolutionise traditional farming methods. Experience a sustainable and efficient
                    approach to farming that maximises yields while minimising environmental impact.</p>
            </div>
            <div class="row justify-content-center gy-4 animation aos-init px-2 px-sm-0" data-aos="fade-up">
                <div class="col-6 col-md-3 col-lg-2">
                    <div class="benefit-card text-center">
                        <img src="assets/images/local.webp" class="img-fluid" alt="">
                        <h5>Local and <br> Sustainable</h5>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <div class="benefit-card text-center">
                        <img src="assets/images/pesticide-free.webp" class="img-fluid" alt="">
                        <h5>Herbicide/ <br> Pesticide-free</h5>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <div class="benefit-card text-center">
                        <img src="assets/images/harvest.webp" class="img-fluid" alt="">
                        <h5>Multiple Harvests <br> Every Year</h5>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <div class="benefit-card text-center">
                        <img src="assets/images/nutient.webp" class="img-fluid" alt="">
                        <h5>Nutrient <br> Absorption</h5>
                    </div>
                </div>
            </div>
            <div class="mid-img text-center">
                <img src="assets/images/mid-img@2x.png" class="img-fluid" alt="">
            </div>
        </div>
    </section>
    <section class="about">
        <div class="container px-4 px-sm-2 px-lg-25">
            <div class="row flex-column-reverse flex-lg-row align-items-lg-center justify-content-center gy-5 mx-0 animation aos-init" data-aos="fade-up">
                <div class="col-9 col-lg-4 px-0 px-sm-2">
                    <img src="assets/images/about.webp" class="img-fluid" alt="">
                </div>
                <div class="d-none d-lg-block col-lg-1"></div>
                <div class="col-lg-5  px-0 px-sm-2">
                    <h2 class="section-title"><span>About GoPurple</span></h2>
                    <p class="section-desc pt-3 mb-4 mb-lg-5">As the first commercial smart indoor vertical farm
                        utilising
                        Fogponics,
                        we employ cutting-edge technology in indoor vertical farming, to grow a wide array of
                        products, delivering superior flavour, better quality, and improved nutrition while
                        minimising uses of land and water consumption.</p>
                    <button class="primary-btn">LEARN MORE</button>
                </div>
            </div>
        </div>
    </section>
    <section class="grow-more px-4 px-sm-2 px-lg-0">
        <div class="container animation aos-init px-0 px-sm-2" data-aos="fade-up">
            <div class="title-group text-center">
                <h2 class="section-title d-inline-block text-green">Grow <span>More With Less</span></h2>
            </div>
            <div class="grow-more-wrapper">
                <div class="row mx-0 justify-content-center gy-1 mx-0">
                    <div class="col-lg-4 px-0 px-lg-0">
                        <div class="grow-card">
                            <h4>Revolutionary Fogponics Technology</h4>
                            <p>The smaller the droplet, the faster the roots can absorb nutrients, leading to faster
                                plant growth. This is the key distinction between hydroponic systems and Fogponics.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 px-0 px-lg-0">
                        <div class="grow-card bg-light-green">
                            <h4 class="text-green">Precision Environmental Control</h4>
                            <p class="text-green">Our production remains unaffected by climate changes such as
                                floods, droughts, or
                                heavy
                                rain. Always in stock, always fresh.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 px-0 px-lg-0">
                        <div class="grow-card bg-mid-green">
                            <h4>Vertical Space Utilisation</h4>
                            <p class="text-light-green">Leveraging innovative methods, our smart indoor vertical
                                farms excel at generating
                                substantial yields within confined spaces, maximising productivity through the
                                efficient
                                use of available resources.</p>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-1 gy-1">
                    <div class="col-lg-6 px-0 px-lg-0">
                        <div class="grow-card water-conservation-card bg-green">
                            <h4><span>97%</span>Water Conservation</h4>
                            <p>making our vertical farm an eco-friendly choice aligned with responsible water
                                management
                                practices.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 px-0 px-lg-0">
                        <div class="grow-card year-round-card bg-purple">
                            <h4>Year-Round Harvests</h4>
                            <p>Through Fogponics, our vertical farm establishes a controlled environment,
                                facilitating
                                year-round farming and yielding up to multiple harvests annually every year
                                depending on
                                the crops we are growing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   <app-subscribe></app-subscribe>
    <section class="blog">
        <div class="container px-4 px-sm-2">
            <div class="blog-container animation aos-init" data-aos="fade-up">
                <div class="row justify-content-between align-items-center mb-4 mx-0">
                    <div class="col-7 px-0 px-lg-25">
                        <h2 class="section-title"><span class="text-green">GoPurple Blogs</span></h2>
                    </div>
                    <div class="col-5 text-end px-0 px-lg-25">
                        <button class="primary-btn purple-btn">VIEW ALL</button>
                    </div>
                </div>
                <div class="row gy-2 justify-content-center mx-0">
                    <div class="col-md-6 col-lg-4 px-0 px-lg-25">
                        <div class="blog-card">
                            <div class="blog-details mb-3">
                                <h5 class="mb-3 fw-bold text-green">The Future of Agriculture: Exploring Fogponic
                                    Technology</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                                    ever
                                    since the 1500s, when an unknown printer.</p>
                            </div>
                            <div class="blog-img position-relative">
                                <img src="assets/images/blog.png" class="h-100 w-100" alt="blog Image">
                                <button class="primary-btn position-absolute">READ MORE</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 px-0 px-lg-25">
                        <div class="blog-card">
                            <div class="blog-details mb-3">
                                <h5 class="mb-3 fw-bold text-green">Water-Saving Wonders: How Fogponics Outshines
                                    Conventional Farming Practices</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy txt</p>
                            </div>
                            <div class="blog-img position-relative">
                                <img src="assets/images/blog.png" class="h-100 w-100" alt="blog Image">
                                <button class="primary-btn position-absolute">READ MORE</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 px-0 px-lg-25">
                        <div class="blog-card">
                            <div class="blog-details mb-3">
                                <h5 class="mb-3 fw-bold text-green">Gopurple: How We Addresses Challenges Faced by
                                    Traditional Agriculture</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy </p>
                            </div>
                            <div class="blog-img position-relative">
                                <img src="assets/images/blog.png" class="h-100 w-100" alt="blog Image">
                                <button class="primary-btn position-absolute">READ MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="review">
        <div class="container">
            <div class="blog-container animation aos-init" data-aos="fade-up">
                <div class="row justify-content-between align-items-center gy-3">
                    <div class="col-lg-4">
                        <h2 class="section-title text-malachite pb-3 pb-lg-1 ">Loved By Many</h2>
                        <div class="slider-nav slick-initialized slick-slider"><div class="slick-list draggable" style="padding: 0px 50px;"><div class="slick-track" style="opacity: 1; width: 414px; transform: translate3d(0px, 0px, 0px);"><div class="slick-slide slick-current slick-center" data-slick-index="0" aria-hidden="true" style="width: 118px;"><div><div style="width: 100%; display: inline-block;">
                                <div class="slider-thumb">
                                    <div class="thumb-img position-relative">
                                        <img src="assets/images/avatar.webp" class="img-fluid" alt="">
                                        <span class="quote position-absolute end-0 bottom-0"><img src="assets/images/quote-icon.svg" alt=""></span>
                                    </div>
                                    <h5>Jane Doe</h5>
                                </div>
                            </div></div></div><div class="slick-slide" data-slick-index="1" aria-hidden="true" style="width: 118px;"><div><div style="width: 100%; display: inline-block; opacity: 0.4;">
                                <div class="slider-thumb">
                                    <div class="thumb-img position-relative">
                                        <img src="assets/images/avatar-2.webp" class="img-fluid" alt="">
                                        <span class="quote position-absolute end-0 bottom-0"><img src="assets/images/quote-icon.svg" alt=""></span>
                                    </div>
                                    <h5>Jane Doe</h5>
                                </div>
                            </div></div></div><div class="slick-slide slick-center" data-slick-index="2" aria-hidden="true" style="width: 118px;"><div><div style="width: 100%; display: inline-block;opacity: 0.4;">
                                <div class="slider-thumb">
                                    <div class="thumb-img position-relative">
                                        <img src="assets/images/avatar.webp" class="img-fluid" alt="">
                                        <span class="quote position-absolute end-0 bottom-0"><img src="assets/images/quote-icon.svg" alt=""></span>
                                    </div>
                                    <h5>Jane Doe</h5>
                                </div>
                            </div></div></div></div></div></div>
                    </div>
                    <div class="col-lg-7">
                        <div class="slider-slide slick-initialized slick-slider"><button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">Previous</button><div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 2109px;"><div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 703px; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;"><div><div style="width: 100%; display: inline-block;">
                                <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book.
                                </p>
                                <p> It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged.</p>
                            </div></div></div><div class="slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style="width: 703px; position: relative; left: -703px; top: 0px; z-index: 998; opacity: 0;"><div><div style="width: 100%; display: inline-block;">
                                <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book.
                                </p>
                                <p> It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged.</p>
                            </div></div></div><div class="slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style="width: 703px; position: relative; left: -1406px; top: 0px; z-index: 998; opacity: 0;"><div><div style="width: 100%; display: inline-block;">
                                <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book.
                                </p>
                                <p> It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged.</p>
                            </div></div></div></div></div><button class="slick-next slick-arrow" aria-label="Next" type="button" style="">Next</button></div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!-- loved by many component start -->
    <app-loved-by-many></app-loved-by-many>
    <!-- loved by many component end -->

   <app-partner-adviser></app-partner-adviser>
</main>
</app-full-page-layout>
