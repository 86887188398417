import { Routes } from '@angular/router';
import { HomeComponent } from './modules/public/home/home.component';
import { AboutUsComponent } from './modules/public/about-us/about-us.component';
import { TeamComponent } from './modules/public/team/team.component';
import { ShopDetailsComponent } from './modules/shop/shop/shop.component';
import { ShopComponent } from './modules/public/shop/shop.component';
import { CartComponent } from './modules/shop/cart/cart.component';
import { Cart1Component } from './modules/shop/cart1/cart1.component';
import { Cart2Component } from './modules/shop/cart2/cart2.component';
import { MyAccountComponent } from './modules/shop/my-account/my-account/my-account.component';
import { PaymentComponent } from './modules/shop/payment/payment.component';
import { PaymentFailedComponent } from './shared/payment-failed/payment-failed.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('../app/modules/shop/my-account/my-account.module').then(
        (m) => m.MyAccountModule
      ),
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'team',
    component: TeamComponent,
  },
  {
    path: 'shop',
    component: ShopComponent,
  },
  {
    path: 'shop/:slugs',
    component: ShopDetailsComponent,
  },
  {
    path: 'cart',
    component: CartComponent,
  },
  {
    path: 'payment',
    component: PaymentComponent,
  },
  {
    path: 'payment-failed',
    component: PaymentFailedComponent,
  },

  { path: '**', redirectTo: 'auth' },
];
