<section class="subscribe">
    <div class="container px-4">
        <div class="subscribe-card mx-auto animation aos-init" data-aos="fade-up">
            <div class="row align-items-center">
                <div class="d-none d-lg-block col-lg-6">
                    <div class="subscribe-card-left">
                        <img src="assets/images/logo-symbol.webp" alt="">
                        <h3 class="section-title text-green m-0">Taste What's Fresh &amp; Healthy</h3>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="subscribe-card-right">
                        <h2 class="section-title mb-4">Subscribe to our <br> <span>Newsletter</span></h2>
                        <p class="section-desc">Fresh updates on product launches, exclusive news, <br> recipes,
                            and
                            farm-to-table secrets
                        </p>
                        <form action="#">
                            <input type="text" class="form-control" placeholder="Enter Email Address">
                        </form>
                        <button type="submit" class="primary-btn light-btn">JOIN NOW</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
