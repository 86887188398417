import { Component, Input } from '@angular/core';
import { ProductDetailsModel } from '../../product/model/product-details.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-images',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './images.component.html',
  styleUrl: './images.component.scss',
})
export class ImagesComponent {
  @Input() productImages! :ProductDetailsModel;

  changeImageOnSelectedImage(image:string){
    this.productImages.thumbnail = image;

  }
}
