import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Feature } from '../../product/model/product-details.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-left-nutrients',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './left-nutrients.component.html',
  styleUrl: './left-nutrients.component.scss',
})
export class LeftNutrientsComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes['feature']);
  }
  @Input() feature!:Feature[];
  
}
