<div class="row align-items-start">
    <div class="col-lg-8 pe-xl-4 pe-3">

        <div class="cart-card pb-0 mb-3">
            <div class="d-flex align-items-md-center align-items-start position-relative p-2 p-sm-3">
                <div class="cart-remove-btn">
                    <a href="javascript::void(0)">REMOVE</a>
                </div>
                <div>
                    <img src="assets/images/product-2.png" alt="">
                </div>
                <div class=" ms-2 ms-sm-3 w-100">
                    <h6>Tiberius Lettuce</h6>
                    <p>Rs. 200</p>
                    <div class="fwt-cart-product-gms d-md-flex align-items-center">
                        <select name="" id="">
                            <option value="">500 gms</option>
                            <option value="">250 gms</option>
                            <option value="">1 kg</option>
                            <option value="">2 kg</option>
                        </select>

                        <div class="qty-input mt-md-0 mt-2">
                            <button class="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                            <input class="product-qty" type="number" name="product-qty" min="0" max="10" value="1">
                            <button class="qty-count qty-count--add" data-action="add" type="button">+</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="remove-cart-set py-2">
                <p class="text-center">REMOVE FROM CART</p>
            </div>
        </div>

        <div class="cart-card pb-0">
            <div class="d-flex align-items-md-center align-items-start position-relative p-2 p-sm-3">
                <div class="cart-remove-btn">
                    <a href="javascript::void(0)">REMOVE</a>
                </div>
                <div>
                    <img src="assets/images/product-2.png" alt="">
                </div>
                <div class=" ms-2 ms-sm-3 w-100">
                    <h6>Tiberius Lettuce</h6>
                    <p>Rs. 200</p>
                    <div class="fwt-cart-product-gms d-md-flex align-items-center">
                        <select name="" id="">
                            <option value="">500 gms</option>
                            <option value="">250 gms</option>
                            <option value="">1 kg</option>
                            <option value="">2 kg</option>
                        </select>

                        <div class="qty-input mt-md-0 mt-2">
                            <button class="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                            <input class="product-qty" type="number" name="product-qty" min="0" max="10" value="1">
                            <button class="qty-count qty-count--add" data-action="add" type="button">+</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="remove-cart-set py-2">
                <p class="text-center">REMOVE FROM CART</p>
            </div>
        </div>
    </div>
    <div class="col-lg-4 ps-xl-4 ps-3 mt-lg-0 mt-4">
        <div class="cart-coupons-details">
            <div class="d-flex align-items-start">
                <span>%</span>
                <div>
                    <h6>Coupons and offers</h6>
                    <p class="mt-2">Save more with coupons and offers</p>
                </div>
            </div>
        </div>
        <div class="cart-coupons-details mt-3">
            <div class="fwt-dooted-border">
                <ul class="d-flex align-items-center justify-content-between mb-3">
                    <li><p>Item total</p></li>
                    <li><p>Rs. 1,500</p></li>
                </ul>
                <ul class="d-flex align-items-center justify-content-between mb-0">
                    <li><p>Delivery fee</p></li>
                    <li><h5>FREE</h5></li>
                </ul>
            </div>
            <div class="fwt-dooted-border">
                <ul class="d-flex align-items-center justify-content-between mb-3">
                    <li><p><b>Grand Total</b></p></li>
                    <li><p><b>Rs. 1,500</b></p></li>
                </ul>
                <ul class="d-flex align-items-center justify-content-between mb-0">
                    <li><h6>Inclusive of all taxes</h6></li>
                </ul>
            </div>
            <div class="payment-checkout-btn mb-2">
                <button class="primary-btn w-100 mt-4">CHECKOUT</button>
            </div>
        </div>
    </div>
</div>
