import { Component, OnInit } from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-about-us-accordian',
  standalone: true,
  imports: [],
  templateUrl: './about-us-accordian.component.html',
  styleUrl: './about-us-accordian.component.scss',
})
export class AboutUsAccordianComponent implements OnInit {
  ngOnInit(): void {
    AOS.init({
      offset: 120,
      once: true,
      duration: 1000,
    });
  }
}
