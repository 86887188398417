import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import 'slick-carousel';

@Component({
  selector: 'app-loved-by-many',
  standalone: true,
  imports: [],
  templateUrl: './loved-by-many.component.html',
  styleUrl: './loved-by-many.component.scss',
})
export class LovedByManyComponent implements OnInit {
  ngOnInit(): void {
    $('.slider-slide').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      asNavFor: '.slider-nav',
    });
    $('.slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slider-slide',
      dots: true,
      arrows: false,
      centerMode: true,
      focusOnSelect: true,
    });
  }
}
