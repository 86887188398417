import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionsStorageService } from '../services/storage.service';
/**
 * To intercept the Http Request and add the auth Token
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private storageService: SessionsStorageService) { }

    private refreshTokenInProgress = false;
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private tokenRefreshedSource = new Subject();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    /**
     * Method to intercept the reuqest and add token
     * @param request out going request
     * @param next call to next intercepter in Pipeline
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        request = this.addAuthenticationToken(request);
        return next.handle(request);

    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        // If we do not have a token yet then we should not set the header.
        let accessToken = this.getAccessToken();
        if (!accessToken) {
            return request;
        }
        // If you are calling an outside domain then do not add the token.
        if (!request.url.startsWith(environment.apiUrl)) {
            return request;
        }

        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${accessToken}`)
        });
    }


    getAccessToken() {
        let auth = this.getAuthFromLocalStorage();
        return auth ? auth : null;
    }

    private getAuthFromLocalStorage(): any {
        try {
            // const storageData = localStorage?.getItem(this.authLocalStorageToken) || '';
            const storageData =    this.storageService.get(this.authLocalStorageToken);
            // const authData = JSON.parse(storageData)

            return storageData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    // private refreshToken() {
    //     if (this.refreshTokenInProgress) {
    //         return new Observable(observer => {
    //             this.tokenRefreshed$.subscribe((): void => {
    //                 observer.next();
    //                 observer.complete();
    //             });
    //         });
    //     } else {
    //         this.refreshTokenInProgress = true;
    //         // return this.auth.triggerRefreshToken()
    //         //     .pipe(tap(() => {
    //         //         this.refreshTokenInProgress = false;
    //         //         this.tokenRefreshedSource.next();
    //         //     }));
    //     }
    // }
}
