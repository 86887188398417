import { Component, OnInit, Renderer2 } from '@angular/core';
import { FullPageLayoutComponent } from '../../layout/full-page-layout/full-page-layout.component';
import { PartnerAdviserComponent } from '../../public/partner-adviser/partner-adviser.component';
import { SubscribeComponent } from '../../public/subscribe/subscribe.component';
import { ImagesComponent } from '../images/images.component';
import { AidsDigestionComponent } from '../aids-digestion/aids-digestion.component';
import { LeftNutrientsComponent } from '../left-nutrients/left-nutrients.component';
import { RightNutrientsComponent } from '../right-nutrients/right-nutrients.component';
import { TeamReviewComponent } from '../team-review/team-review.component';
import { ProductsComponent } from '../products/products.component';
import AOS from 'aos';
import $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, of, catchError, Subscription } from 'rxjs';
import { ProductService } from '../../product/services/product.service';
import {
  ProductDetailsModel,
  Variant,
} from '../../product/model/product-details.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CartService } from '../../../core/services/cart.service';
import { StarRatingComponent } from '../start-rating/star-rating/star-rating.component';
import { ShopItemListComponent } from '../../../shared/shop-item-list/shop-item-list.component';

// import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-shop',
  standalone: true,
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
  imports: [
    FullPageLayoutComponent,
    PartnerAdviserComponent,
    SubscribeComponent,
    ImagesComponent,
    AidsDigestionComponent,
    LeftNutrientsComponent,
    RightNutrientsComponent,
    TeamReviewComponent,
    ProductsComponent,
    CommonModule,
    FormsModule,
    StarRatingComponent,
    ShopItemListComponent,
  ],
})
export class ShopDetailsComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private cartService: CartService,
    private route: ActivatedRoute,
    private productService: ProductService,
    private router: Router
  ) {}
  slug!: string;
  quantityCount: number = 0;
  cartUniqeId!: string;
  selectedWeight!: Variant;
  productDetails!: ProductDetailsModel;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    AOS.init({
      offset: 120,
      once: true,
      duration: 1000,
    });
    this.getCartUniqeId();
    this.loadDocument();

    const script = this.renderer.createElement('script');
    script.src = '../../../../assets/js/shop.js';
    this.renderer.appendChild(document.body, script);
  }

  ngOnDestroy() {
    const script = document.querySelector(
      'script[src="../../../../assets/js/shop.js"]'
    );
    if (script) {
      this.renderer.removeChild(document.body, script);
    }
  }

  getCartUniqeId() {
    this.cartUniqeId = this.cartService.getCartUID();
  }

  loadDocument() {
    const sb = this.route.paramMap
      .pipe(
        switchMap((params: any) => {
          // get slug from URL
          this.slug = params.get('slugs');
          if (this.slug) {
            return this.productService.getItemBySlug(
              this.slug,
              this.cartUniqeId
            );
          } else return of(undefined);
        }),
        catchError((errorMessage) => {
          return of(undefined);
        })
      )
      .subscribe((res: ProductDetailsModel | undefined) => {
        if (!res) {
          this.router.navigate(['/shop'], {
            relativeTo: this.route,
          });
        } else {
          this.productDetails = res;
          this.selectedWeight = this.productDetails.variant[0];
        }
      });
    this.subscriptions.push(sb);
  }

  updateQuantity(item: Variant) {
    const productCartValues = {
      cartUniqueId: this.cartUniqeId,
      productId: item.id,
      productVariantId: item.productVariantId,
      qty: 1,
    };
    this.cartService.updateCartInfo(productCartValues).subscribe((resp) => {
      console.log('Cart Updated Success');
      item.cartInfo = {
        id: 0,
        cartUniqueId: this.cartUniqeId,
        productId: item.id,
        productVariantId: item.productVariantId,
        qty: 1,
      };
    });
  }

  addQuntity(item: Variant) {
    item.cartInfo.qty++;
    const newItem = {
      cartUniqueId: this.cartUniqeId,
      productId: item.id,
      productVariantId: item.productVariantId,
      qty: item.cartInfo.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');
    });
  }
  decreaseQuantity(item: Variant) {
    item.cartInfo.qty--;
    const newItem = {
      cartUniqueId: this.cartUniqeId,
      productId: item.id,
      productVariantId: item.productVariantId,
      qty: item.cartInfo.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');
    });
  }

  addCartInfo() {
    const cartUpdate = {
      cartUniqueId: this.cartUniqeId,
      productId: this.productDetails.id,
      productVariantId: this.selectedWeight.productVariantId, /// nedd to discuss
      qty: this.quantityCount,
    };
    this.cartService.updateCartInfo(cartUpdate).subscribe((resp) => {
      console.log('Cart Updated Success');
    });
  }

  // applyOffers() {
  //   const modalRef = this.modalService.open(CouponAndOfferComponent, {
  //     centered: true,
  //     size: 'confirm-size',
  //   });

  //   modalRef.result.then(
  //     (isApplicable) => {
  //       if (isApplicable == true) {
  //         this.getCouponCode();
  //       } else {
  //         this.couponError = true;
  //       }
  //     }
  //     // () => this.getCouponCode(),
  //   );
  // }
}
