<app-full-page-layout>
    <main>
        <section class="hero-abp backImg mesage-bootom">
            <div class="container align-self-center px-4 px-sm-2">
                <div class="text-center">
                    <h2 class="section-desc teamText">At GoPurple, we are on a mission to create a <br> better
                        world by providing innovative and <br class="d-none d-lg-block"> sustainable indoor farming.
                    </h2>

                </div>
            </div>
            <div class="container text-center  px-4 px-lg-0">

                <img src="assets/images/1.webp" class="img-fluid teamImg" alt="">
            </div>
            <div class=" container text-center  founder-container px-4 px-sm-2">

                <div class="row m-auto section-width">
                    <div class="col-12 col-lg-auto px-0">
                        <div>
                            <span class="Message-From-Our-Fou"> Message From <span class="ourfound">Our
                                    Founders</span></span>
                            <div class="mt-2 mt-lg-2">
                                <img src="assets/images/2.webp" class="img-fluid px-0 px-md-3 px-lg-0 w-100" alt="">
                            </div>
                        </div>

                    </div>
                    <!-- <div class="col-12 col-lg-1"></div> -->
                    <div class="col-12 col-lg-6 messg-css px-0 px-md-2">
                        <div class="Facing-a-future-wher ps-0 ps-md-2">
                            Facing a future where food, water, and energy demands surge, GoPurple is dedicated to
                            pioneering innovative Fogponic solutions and smart indoor farming.
                            <br>
                            <br>

                            Bridging this gap sustainably, we strive to meet rising needs while safeguarding our
                            precious natural resources for a resilient and thriving tomorrow.
                        </div>
                        <div class="To-your-health-and-t ps-0 ps-md-2">
                            To your health and the Earth
                            <div class="text-style-1"> Brahmmjyot & Satya</div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="benefits">
            <div class="container px-4 px-sm-2">
                <div class="title-group text-center animation aos-init" data-aos="fade-up">
                    <h2 class="section-title d-inline-block text-green"><span>Our Vision</span></h2>

                </div>
                <div class="row content-size justify-content-center gy-4 animation aos-init" data-aos="fade-up">
                    <!-- <div class="col-12 col-md-6 mt-0 mt-lg-0 col-lg-1"></div> -->
                    <div class="col-12 col-md-6 mt-0 mt-lg-0 col-lg-5 ">
                        <div class="vision-top">

                            <div class=" mt-lg-5 mb-4 mb-lg-4 vision-text">Empowering a sustainable future through
                                innovative smart indoor farming solutions.
                                GoPurple envisions a world where fresh, healthy, and nutritious produce is accessible to
                                all, fostering environmental responsibility and supporting local communities.</div>
                        </div>
                        <div class="visionImg">
                            <img src="assets/media/images/4.webp" class="img-impact-4 visison-img" alt="">
                        </div>
                    </div>
                    <div class="col-12 visionFlex col-md-6 col-lg-5">
                        <div class="visionImg">
                            <img src="assets/media/images/3.webp" class="img-impact-4 visison-img" alt="">
                        </div>
                        <div class="col-12 col-lg-12">

                            <div class="mt-lg-4  mb-4 mb-lg-2 vision-text">Our commitment is to lead the way in
                                cutting-edge smart Fogponic technology,
                                revolutionising the agriculture landscape to meet the challenges of a changing climate
                                and growing global population. At GoPurple we strive to be the one stop solution to not
                                just fresh produce but also the absolute epitome of Smart CEA (Controlled Environment
                                Agriculture) farming technology.</div>
                        </div>

                    </div>
                    <!-- <div class="col-12 col-md-6 mt-0 mt-lg-0 col-lg-1"></div> -->

                </div>
                <div class="mid-img text-center">
                    <img src="assets/images/5.webp" class="img-fluid" alt="">
                </div>
            </div>
        </section>

        <section class="about backgImg about-mobile">
            <div class="container px-4 px-sm-2">
                <div class="row  flex-lg-row align-items-lg-center justify-content-center gy-5 mx-0 animation aos-init"
                    data-aos="fade-up">

                    <div data-aos="fade-up"
                        class="d-none d-lg-block col-lg-1 row  flex-lg-row align-items-lg-center justify-content-center gy-5 mx-0 animation aos-init">
                    </div>
                    <div class="title-group text-center animation aos-init mission-mobile-bottom px-0 px-md-2" data-aos="fade-up">
                        <h2 class="section-title d-inline-block missionText "><span>Our Mission</span></h2>
                        <p class="section-desc d-none d-lg-block section-text mt-4 mt-lg-5">At GoPurple, our mission is
                            to create a better world by
                            providing innovative and sustainable indoor farming solutions using Fogponic technology. We
                            strive to make fresh, healthy, and nutritious produce accessible to everyone, ensuring
                            quality through locally grown, environmentally responsible farming practices. <span
                                class="missionPara">Additionally,
                                we support local farmers by collaborating with them and creating new opportunities for
                                growth and development, while protecting the environment for future generations.</span>
                        </p>
                        <div class=" d-block d-block-mission d-lg-none section-text mt-4 mt-lg-5">At GoPurple, our
                            mission is to create a better world by
                            providing innovative and sustainable indoor farming solutions using Fogponic technology. We
                            strive to make fresh, healthy, and nutritious produce accessible to everyone, ensuring
                            quality through locally grown, environmentally responsible farming practices. <div
                                class="missionPara">Additionally,
                                we support local farmers by collaborating with them and creating new opportunities for
                                growth and development, while protecting the environment for future generations.</div>
                        </div>
                    </div>
                    <div class="text-center Sustainability-inno-mobile">
                        <h2 class="section-desc Sustainability-inno">Sustainability, innovation, and responsible farming
                            <br class=" d-none d-lg-block ">
                            practices are the cornerstones of our mission.
                        </h2>

                    </div>
                </div>
            </div>
        </section>
        <section class="benefits">
            <div class="container px-4 px-sm-2">
                <div class="row make-diiference flex-column-reverse flex-lg-row  justify-content-center gy-5 mx-0 animation aos-init"
                    data-aos="fade-up">
                    <div class="col-9 col-lg-5">
                        <img src="assets/images/6.webp" class="img-impact-6" alt="">
                    </div>
                    <div class="d-none d-lg-block col-lg-1"></div>
                    <div class="col-12 col-lg-auto difference px-0 px-md-2">
                        <h2 class="offering">We Make a difference by offering</h2>
                        <p class="Completely-soilless ">Completely soilless indoor farming without dependency on
                            external climate.</p>
                        <p class="Completely-soilless ">Faster growth cycles, offering multiple harvests annually for
                            various crops.</p>
                        <p class="Completely-soilless ">Healthier crops with higher nutrition levels compared to
                            traditional farming methods.</p>
                        <p class="Completely-soilless ">Farm-to-fork freshness, with produce harvested on the same day
                            as delivery.</p>
                        <p class="Completely-soilless ">Stable prices, unaffected by market fluctuations, ensuring
                            affordability.</p>
                        <p class="Completely-soilless ">Capability to grow root vegetables, leafy crops, and high-value
                            crops.</p>

                    </div>
                </div>
            </div>
            <section class="A-Healthier-You-A-H healthierRectangle">
                <!-- <div class="mt-8 mt-lg-8">
                    <marquee behavior="scroll" style="width: 100%; animation: moveSlideshow 5s linear infinite;" scrollamount="20"  value="-1" direction="left"> A Healthier You, A Healthier Planet . A Healthier You,
                        A Healthier Planet . A
                        Healthier You, A Healthier Planet . A
                        Healthier You, A Healthier Planet . A
                        Healthier You, A Healthier Planet . A
                        Healthier You,</marquee>
                </div> -->
                <div class="marquee mt-8 mt-lg-8">
                    <div class="track">
                        <div class="content">&nbsp;A Healthier You, A Healthier Planet . A Healthier You, A Healthier
                            Planet . A Healthier You, A Healthier Planet . A Healthier You, A Healthier Planet . </div>
                    </div>
                </div>
            </section>

        </section>
        <section class="review">
            <div class="container px-4 px-sm-2">
                <div class="blog-container animation aos-init" data-aos="fade-up">
                    <div class="row justify-content-between align-items-center mb-4 mx-0">
                        <div class="text-center">
                            <h2 class="section-title text-center"><span class="section-title">Meet The Team</span></h2>
                        </div>

                    </div>
                    <div class="row gy-2 justify-content-center mx-0">
                        <div class="col-md-6 col-lg-4 px-0 px-md-2">
                            <div class="blog-card">
                                <div class="blog-img position-relative">
                                    <img src="assets/images/impact-5.webp" class="h-100 w-100" alt="blog Image">

                                </div>
                                <div class="blog-details mb-3">
                                    <h5 class="fw-bold team-name-color mt-2 mt-lg-2">TBrahmmjyot Singh Kalsi</h5>
                                    <h6 class="container team-position-name mt-2 mt-lg-2 px-0">Founder, CEO and Managing
                                        Director</h6>
                                    <p class="mt-2 mt-lg-2">Brahmmjyot has over 14 years of experience as an
                                        entrepreneur along with 3 years
                                        of experience in the F&B industry and 5 years of experience researching and
                                        developing Controlled Environment Agriculture technology.
                                    </p>
                                    <p class="pt-2"> He founded GreenGo in June of 2018. His responsibilities include
                                        technology
                                        development, daily operations, R&D and team development.</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 px-0 px-md-2">
                            <div class="blog-card">
                                <div class="blog-img position-relative">
                                    <img src="assets/images/impact-5.webp" class="h-100 w-100" alt="blog Image">

                                </div>
                                <div class="blog-details mb-3">
                                    <h5 class="fw-bold team-name-color mt-2 mt-lg-2">Satya Raju</h5>
                                    <h6 class="container team-position-name mt-2 mt-lg-2 px-0">Founder, CEO and Managing
                                        Director</h6>
                                    <p class="mt-2 mt-lg-2">Satya has over 13 Years of experience in the Hotel Industry,
                                        FMCG and retail
                                        sector and 5 years of experience in Vertical Farming. </p>
                                    <p class="pt-2"> He brings his immense experience in the FMCG sector to give a boost
                                        to GoPurple
                                        in the fields of marketing and sales among his other responsibilities which
                                        include execution of product development, market launch, sales strategies,
                                        forecasting and projections.</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 px-0 px-md-2">
                            <div class="blog-card">
                                <div class="blog-img position-relative">
                                    <img src="assets/images/impact-4.webp" class="h-100 w-100" alt="blog Image">
                                </div>
                                <div class="blog-details mb-3">
                                    <h5 class="fw-bold team-name-color mt-2 mt-lg-2">Vinod Ramani</h5>
                                    <h6 class="container team-position-name mt-2 mt-lg-2 px-0">CTO and Director of Strategy and
                                        Technical Development</h6>
                                    <p class="mt-2 mt-lg-2">Vinod is a tech entrepreneur with over 35 years of business
                                        experience. As a
                                        passionate agroponics enthusiast for many years, he has now dedicated his
                                        expertise to developing cutting-edge automated monitoring and control systems
                                        rooted in plant biology. </p>
                                    <p class="pt-2">Vinod's role encompasses technical responsibilities that drive our
                                        commitment to
                                        innovation and excellence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-subscribe></app-subscribe>
        <app-partner-adviser></app-partner-adviser>


    </main>
    <script>
        $('.slider-slide').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-slide',
            dots: true,
            arrows: false,
            centerMode: true,
            focusOnSelect: true
        });
    </script>
</app-full-page-layout>
