import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './star-rating.component.html',
  styleUrl: './star-rating.component.scss'
})
export class StarRatingComponent {
  @Input() rating!: number;
  @Input () ratingColorFlag:boolean=false;
  get stars() {
    return Array(Math.floor(this.rating)).fill(0);
  }


  getRemainingStars(){
    const remainingStars = 5 - this.stars.length;
    return Array(remainingStars).fill(0);
  }
}
