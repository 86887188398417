<app-full-page-layout>
    <div class="fwt-cart-section position-relative">
        <div class="container-sm px-4 px-sm-2 ">


            <div class="container-sm ">

                <div class="payment-img"> <img src="assets/media/svg/cross.svg" alt=""></div>
                <div class="order-failed-text">Order failed !
                </div>
                <div class="payment-failed-text">We are unable to place your order. Please try again after some time
                </div>
                <div class="shipping-address pb-0">
                    <div class="home-page">
                        <button class="home-btn  " type="button" (click)="routetoHomePage()">Back to homePage
                        </button>
                    </div>
                </div>
            </div>

        </div>

    </div>
</app-full-page-layout>