import { Injectable } from '@angular/core';

// import { ReviewsItem, ReviewsModel, ReviewsRequestModel } from '../model/product';
// import { ReviewsDetailsModel } from '../model/product-details.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AppHttpClient } from '../../../../core/services/app-http.service';
import {
  AddReviewModel,
  AddReviewResponseModel,
  ReviewsModel,
  ReviewsModelDetails,
  ReviewsRequestModel,
} from '../model/reviews.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  private baseUrl = `${environment.apiUrl}/app/v1/review`;

  constructor(private http: AppHttpClient) {}

  all(listRequestModel: ReviewsRequestModel) {
    return this.http.post<ReviewsModel>(
      `${this.baseUrl}/list`,
      listRequestModel
    );
  }

  // getReviewsDDL() {
  //   return this.http.get<ReviewsItem[]>(this.baseUrl + '/ddl');
  // }
  getItemBySlug(
    slug: string,
    cartUniqeId: string
  ): Observable<ReviewsModelDetails> {
    return this.http.get<ReviewsModelDetails>(
      `${this.baseUrl}/slug/${slug}?cartUniqueId=${cartUniqeId}`
    );
  }
  addNewReview(addReview: AddReviewModel) {
    return this.http.post<AddReviewResponseModel>(
      `${this.baseUrl}/`,
      addReview
    );
  }
}
