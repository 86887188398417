<div class="shippingAdresss">
    <div class="pe-4 ">
        <div class="fwt-cart-card d-flex align-items-center justify-content-between mb-4">
            <div>
                <h2 class="section-title mb-1 d-sm-block">Shipping Address</h2>
            </div>
            <div>
                <p class="step-1 d-sm-none d-block">Step 2/3</p>
            </div>
        </div>
    </div>
</div>
<form [formGroup]="shippingAddressForm" (ngSubmit)="onSubmit()">
    <!-- <div class="row align-items-start">
    <div class="col-lg-8 pe-0 pe-sm-3 pe-xl-4 ps-0 ps-sm-2"> -->

    <div class="shipping-address pb-0 mb-3">
        <div class="row">
            <div class="col-12 mb-3 px-4 px-sm-2 position-relative">
                <input type="text" id="" name="fullName" required="required" formControlName="fullName"
                    class="form-control form-fields" autocomplete="off" placeholder="Enter Full Name"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('fullName') && markAsTouched('fullName')  }" />
                <!-- <label for="fullName">Enter Full Name</label> -->
                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                            validation: 'required',
                            message: 'Fullname is required',
                            control: shippingAddressForm.controls['fullName']
                          }"></ng-container>

            </div>
            <div class="col-12 col-sm-6 mb-3 px-4 px-sm-2 position-relative">
                <input type="tel" id="" name="mobile" required="required" formControlName="mobile"
                    class="form-control form-fields" autocomplete="off" placeholder="+91 Enter Mobile Number"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('mobile') && markAsTouched('mobile')  }" />
                <!-- <label for="mobile"><span>+91 </span>Enter Mobile Number</label> -->
                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                            validation: 'required',
                            message: 'Mobile No is required',
                            control: shippingAddressForm.controls['mobile']
                          }"></ng-container>
                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                            validation: 'pattern',
                            message: 'Mobile No is invalid',
                            control: shippingAddressForm.controls['mobile']
                          }"></ng-container>

            </div>
            <div class="col-12 col-sm-6 mb-3 px-4 px-sm-2 position-relative">
                <input type="text" id="" name="name" required="required" formControlName="email"
                    placeholder="Enter email Address" class="form-control form-fields" autocomplete="off"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('email') && markAsTouched('email')  }" />
                <!-- <label for="email">Enter email Address</label> -->
                <ng-container>
                    <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                               validation: 'required',
                               message: 'Email is required',
                              control: shippingAddressForm.controls['email']
                                }"></ng-container>
                    <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                               validation: 'email',
                                message: 'Email is invalid',
                                  control: shippingAddressForm.controls['email']
                                  }"></ng-container>
                    <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                  validation: 'minLength',
                                   message: 'Email should have at least 3 symbols',
                                    control: shippingAddressForm.controls['email']
                                 }"></ng-container>
                    <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                                validation: 'maxLength',
                                   message: 'Email should have maximum 360 symbols',
                                 control: shippingAddressForm.controls['email']
                                   }"></ng-container>
                </ng-container>
            </div>
            <div class="col-12 mb-3 px-4 px-sm-2 position-relative">
                <input type="text" id="" name="address" required="required" formControlName="address"
                    class="form-control form-fields" autocomplete="off" placeholder="Address Line 1"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('address') && markAsTouched('address')  }" />
                <!-- <label for="name">Address Line 1</label> -->
                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                       validation: 'required',
                       message: 'Address is required',
                      control: shippingAddressForm.controls['address']
                        }"></ng-container>
            </div>
            <div class="col-12 col-sm-6 mb-3 px-4 px-sm-2">
                <input type="text" name="locality" placeholder="Locality/Area" formControlName="locality"
                    class="form-control form-fields" autocomplete="off"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('locality') && markAsTouched('locality')  }">
                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                       validation: 'required',
                       message: 'Locality is required',
                      control: shippingAddressForm.controls['locality']
                        }"></ng-container>
            </div>
            <div class="col-12 col-sm-6 mb-3 px-4 px-sm-2">
                <input type="text" name="landmark" placeholder="Landmark" formControlName="landmark"
                    class="form-control form-fields" autocomplete="off"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('landmark') && markAsTouched('landmark')  }">
                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                       validation: 'required',
                       message: 'Landmark is required',
                      control: shippingAddressForm.controls['landmark']
                        }"></ng-container>
            </div>
            <div class="col-12 col-sm-6 mb-3 px-4 px-sm-2 position-relative">
                <input type="text" id="" name="pincode" required="required" formControlName="pincode"
                    class="form-control form-fields" autocomplete="off" placeholder="Pin Code"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('pincode') && markAsTouched('pincode')  }" />

                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                       validation: 'required',
                       message: 'Pincode is required',
                      control: shippingAddressForm.controls['pincode']
                        }"></ng-container>
            </div>
            <div class="col-12 col-sm-6 mb-3 px-4 px-sm-2 position-relative">
                <input type="text" id="" name="city" required="required" formControlName="city"
                    class="form-control form-fields" autocomplete="off" placeholder="City"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('city') && markAsTouched('city')  }" />

                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                       validation: 'required',
                       message: 'City is required',
                      control: shippingAddressForm.controls['city']
                        }"></ng-container>
            </div>
            <div class="col-12 col-sm-6 mb-3 px-4 px-sm-2" *ngIf="stateList">
                <select name="state" id="" formControlName="state" class="form-control form-fields text-white"
                    autocomplete="off"
                    [ngClass]="{ 'is-invalid':formSubmitted && isControlInvalid('state') && markAsTouched('state')  }">
                    <option value="" class="text-white">Select State</option>
                    <option [value]="states.name" *ngFor="let states of stateList">{{states.name}}</option>
                    <!-- <option value="India">India</option>
                            <option value="USA">USA</option>
                            <option value="UK">UK</option> -->
                </select>
                <ng-container *ngIf="formSubmitted" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                       validation: 'required',
                       message: 'State is required',
                      control: shippingAddressForm.controls['state']
                        }"></ng-container>
            </div>
        </div>
    </div>
    <div class="shipping-address pb-0">
        <div class="payment-checkout-btn mb-2">
            <button class="primary-btn w-100 " type="submit">
                <ng-container *ngIf="!isLoading; else loading;">
                    SAVE ADDRESS
                </ng-container>
                <ng-template #loading>
                    <div class="spinner-border spinner-border-sm spinner-circle-size" role="status">
                    </div>
                </ng-template></button>
        </div>
    </div>
    <!-- </div> -->

    <!-- 
    <div class="col-lg-4 ps-xl-4 ps-3 mt-lg-0 mt-4">
        <div class="cart-coupons-details">
            <div class="d-flex align-items-start">
                <span>%</span>
                <div>
                    <h6>Coupons and offers</h6>
                    <p class="mt-2">Save more with coupons and offers</p>
                </div>
            </div>
        </div> -->

    <!-- <div class="cart-coupons-details mt-3" *ngIf="cartDetails">
            <div class="fwt-dooted-border">
                <ul class="d-flex align-items-center justify-content-between mb-3">
                    <li>
                        <p>Item total</p>
                    </li>
                    <li>
                        <p>Rs. {{cartDetails.totalAmount}}</p>
                    </li>
                </ul>
                <ul class="d-flex align-items-center justify-content-between mb-0">
                    <li>
                        <p>Delivery fee</p>
                    </li>
                    <li>
                        <h5>{{cartDetails.deliveryCharges}}</h5>
                    </li>
                </ul>
            </div>
            <div class="fwt-dooted-border">
                <ul class="d-flex align-items-center justify-content-between mb-3">
                    <li>
                        <p><b>Grand Total</b></p>
                    </li>
                    <li>
                        <p><b>Rs. {{cartDetails.totalAmount}}</b></p>
                    </li>
                </ul>
                <ul class="d-flex align-items-center justify-content-between mb-0">
                    <li>
                        <h6>Inclusive of all taxes</h6>
                    </li>
                </ul>
            </div>
            <div class="payment-checkout-btn mb-2">
                <button class="primary-btn w-100 mt-4" type="button">
                    <ng-container *ngIf="!isLoading; else loading;">
                        CHECKOUT
                    </ng-container>
                    <ng-template #loading>
                        <div class="spinner-border spinner-border-sm spinner-circle-size" role="status">
                        </div>
                    </ng-template></button>
            </div>
        </div> -->


    <!-- </div> -->

    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
        <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
            <div class="fv-plugins-message-container">
                <div class="fv-error-block">
                    {{ message }}
                </div>
            </div>
        </ng-container>
    </ng-template>
    <!-- </div> -->
</form>