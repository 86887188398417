import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonCartComponent } from '../common-cart/common-cart.component';
import { CartItem, CartItemList } from '../model/cart.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProductItem } from '../../product/model/product';
import { CartService } from '../../../core/services/cart.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Router } from '@angular/router';
import { TotalAmountCheckoutComponent } from '../total-amount-checkout/total-amount-checkout.component';

@Component({
  selector: 'app-cart1',
  standalone: true,
  templateUrl: './cart1.component.html',
  styleUrl: './cart1.component.scss',
  imports: [
    CommonCartComponent,
    CommonModule,
    FormsModule,
    TotalAmountCheckoutComponent,
  ],
})
export class Cart1Component implements OnInit {
  ngOnInit(): void {}
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,

    private cartService: CartService
  ) {}
  // selectedCartItem!: CartItem;

  @Input() cartDetails!: CartItemList;
  @Input() cartUniqeID!: string;
  @Input() activeTab: string = 'cart2';

  @Output() dataEvent = new EventEmitter<string>();
  @Output() cartUpdate = new EventEmitter<boolean>();
  @Output() activeTabEvent = new EventEmitter<string>();
  sendDataToParent() {
    const tokenCheck = this.authenticationService.getToken();
    if (tokenCheck) {
      const data = 'cart2';
      this.dataEvent.emit(data);
      // this.router.navigate(['/cart']);
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  updateQuantityZero(item: CartItem) {
    const productCartValues = {
      cartUniqueId: this.cartUniqeID,
      productId: item.productId,
      productVariantId: item.productVariantId,
      qty: 0,
    };
    this.cartService.updateCartInfo(productCartValues).subscribe((resp) => {
      console.log('Cart Updated Success');
      const data = true;
      this.cartUpdate.emit(data);
    });
  }

  decreaseQuantity(item: CartItem) {
    item.qty--;
    const newItem = {
      cartUniqueId: this.cartUniqeID,
      productId: item.productId,
      productVariantId: item.productVariantId,
      qty: item.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');

      this.cartUpdate.emit(true);
    });
  }

  addQuntity(item: CartItem) {
    item.qty++;
    const newItem = {
      cartUniqueId: this.cartUniqeID,
      productId: item.productId,
      productVariantId: item.productVariantId,
      qty: item.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');

      this.cartUpdate.emit(true);
    });
  }

  handleActiveTab(data: string) {
    this.activeTab = data;
    this.activeTabEvent.emit(this.activeTab);
  }
}
