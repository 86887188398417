import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppHttpClient } from '../../../core/services/app-http.service';
import { ProductItem, ProductModel, ProductRequestModel } from '../model/product';
import { ProductDetailsModel } from '../model/product-details.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private baseUrl = `${environment.apiUrl}/app/v1/product`;

  constructor(private http: AppHttpClient) {}

  all(listRequestModel: ProductRequestModel) {
    return this.http.post<ProductModel>(`${this.baseUrl}/list`, listRequestModel);
}

  getProductDDL() {
    return this.http.get<ProductItem[]>(this.baseUrl + '/ddl');
  }
  getItemBySlug(slug:string,cartUniqeId:string) : Observable<ProductDetailsModel> {
    return this.http.get<ProductDetailsModel>(`${this.baseUrl}/slug/${slug}?cartUniqueId=${cartUniqeId}`);
  }
}
