<div class="fwt-add-img-section d-sm-block d-none">
    <div class="fwt-add-img">
        <img src="{{productImages.thumbnail}}" class="img-fluid" alt="">
    </div>
    <div class="row">
        <div class="col-4" *ngFor="let image of productImages.multipleImages">
            <div class="fwt-add-child-img">
                <img src="{{image}}" alt="" (click)="changeImageOnSelectedImage(image)">
            </div>
        </div>

    </div>
</div>

<!-- <div class="fwt-add-img-slider d-sm-none d-block">

    <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            
            <div class="swiper-slide" class="col-12" *ngFor="let image of productImages.multipleImages">
                <div class="fwt-add-child-img">
                    <img src="{{image}}" alt="" (click)="changeImageOnSelectedImage(image)">
                </div>
            </div>
     
        </div>
    </div>
</div> -->
<div class="fwt-add-img-slider d-sm-none d-block">
    <h4>Butterhead Lettuce</h4>
    <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let image of productImages.multipleImages">
                <div class="fwt-add-img">
                    <img src="{{image}}" class="img-fluid" alt="">
                </div>
            </div>
            <!-- <div class="swiper-slide">
            <div class="fwt-add-img">
                <img src="assets/images/lettuce-copy-2@3x.png" class="img-fluid" alt="">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="fwt-add-img">
                <img src="assets/images/lettuce-copy-2@3x.png" class="img-fluid" alt="">
            </div>
          </div>
          <div class="swiper-slide">
            <div class="fwt-add-img">
                <img src="assets/images/lettuce-copy-2@3x.png" class="img-fluid" alt="">
            </div>
          </div> -->
        </div>
    </div>
</div>