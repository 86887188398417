import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import AOS from 'aos';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { PartnerAdviserComponent } from '../partner-adviser/partner-adviser.component';
import { FullPageLayoutComponent } from '../../layout/full-page-layout/full-page-layout.component';
import { CategoryService } from '../../category/services/category.service';
import { CategoryItem } from '../../category/model/category';
import { CommonModule } from '@angular/common';
import {
  ProductItem,
  ProductModel,
  ProductRequestModel,
} from '../../product/model/product';
import { ProductService } from '../../product/services/product.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { CartService } from '../../../core/services/cart.service';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Variant } from '../../product/model/product-details.model';
import { ShopItemListComponent } from '../../../shared/shop-item-list/shop-item-list.component';
@Component({
  selector: 'app-shop',
  standalone: true,
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
  imports: [
    SubscribeComponent,
    PartnerAdviserComponent,
    FullPageLayoutComponent,
    CommonModule,
    NgSelectModule,
    RouterModule,
    FormsModule,
    ShopItemListComponent,
  ],
})
export class ShopComponent implements OnInit {
  productListItem!: ProductModel;
  selectedProductVarinat!: Variant;
  categoryListItem!: CategoryItem[];
  cartUniqeID!: string;
  isAddClicked: boolean = true;
  listRequestModel: ProductRequestModel = {
    page: 1,
    limit: 30,
    sortBy: '',
    sortDirection: '',
    search: '',
    filter: {
      cartUniqueId: this.cartUniqeID,
      categoryId: 0,
    },
  };
  activeCategoryId: number = 0;
  quantityCount: number = 0;
  // isAllActive:boolean = false;
  constructor(
    private categoryService: CategoryService,
    private cd: ChangeDetectorRef,
    private productService: ProductService,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    AOS.init({
      offset: 120,
      once: true,
      duration: 1000,
    });

    this.getUUid();
    this.getProductList();
    this.getCategoryDdlist();
  }

  updateQuantity(item: ProductItem, itemSelectedVaraiant: any) {
    const productCartValues = {
      cartUniqueId: this.cartUniqeID,
      productId: item.id,
      productVariantId: itemSelectedVaraiant.productVariantId,
      qty: 1,
    };
    this.cartService.updateCartInfo(productCartValues).subscribe((resp) => {
      console.log('Cart Updated Success');
      item.selectedVariant.cartInfo = {
        id: 0,
        cartUniqueId: this.cartUniqeID,
        productId: item.id,
        productVariantId: itemSelectedVaraiant.productVariantId,
        qty: 1,
      };
    });
  }

  addQuntity(item: ProductItem, itemSelectedVaraiant: any) {
    item.selectedVariant.cartInfo.qty++;
    const newItem = {
      cartUniqueId: this.cartUniqeID,
      productId: item.id,
      productVariantId: itemSelectedVaraiant.productVariantId,
      qty: item.selectedVariant.cartInfo.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');
    });
  }
  decreaseQuantity(item: ProductItem, itemSelectedVaraiant: any) {
    item.selectedVariant.cartInfo.qty--;
    const newItem = {
      cartUniqueId: this.cartUniqeID,
      productId: item.id,
      productVariantId: itemSelectedVaraiant.productVariantId,
      qty: item.selectedVariant.cartInfo.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');
    });
  }
  itemSortByPrice() {}

  getUUid() {
    this.cartUniqeID = this.cartService.getCartUID();
    if (this.cartUniqeID) {
      this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
    } else {
      this.cartUniqeID = this.cartService.setCartUID();
      // this.cartUniqeID = categoryUuid;
      this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
      this.cd.detectChanges();
    }
  }

  getCategoryDdlist() {
    this.categoryService.getCategoryDDL().subscribe((resp) => {
      this.categoryListItem = resp;
    });
  }

  getProductList() {
    // this.isAllActive = true;
    this.activeCategoryId = 0;
    this.fetchProductListByID(this.activeCategoryId);
  }
  activeCategoryClass(classsId: number) {
    this.activeCategoryId = classsId;
  }
  fetchProductListByID(categoryItemID: number) {
    // this.isAllActive = false;
    this.activeCategoryClass(categoryItemID);
    this.listRequestModel.filter.categoryId = categoryItemID;
    this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
    this.productService.all(this.listRequestModel).subscribe((resp) => {
      this.productListItem = resp;
      this.productListItem.items = this.productListItem.items.map((item) => {
        item.selectedVariant = item.variants[0];
        return item;
      });
      // this.listRequestModel.filter.categoryId = 0;
    });
  }

  itemSort = [
    { title: 'Featured' },
    { title: 'Price : Low to high' },
    { title: 'Price : High to low' },
  ];

  getListShort(shortId: any) {
    if (shortId.target.value == 'Price : Low to high') {
      this.listRequestModel.sortBy = 'priceStartsFrom';
      this.listRequestModel.sortDirection = 'asc';
    } else if (shortId.target.value == 'Price : High to low') {
      this.listRequestModel.sortBy = 'priceStartsFrom';
      this.listRequestModel.sortDirection = 'desc';
    } else if (shortId.target.value == 'Featured') {
      this.listRequestModel.sortBy = '';
      this.listRequestModel.sortDirection = '';
    }
    this.productService.all(this.listRequestModel).subscribe((resp) => {
      this.productListItem = resp;
      this.productListItem.items = this.productListItem.items.map((item) => {
        item.selectedVariant = item.variants[0];
        return item;
      });
      this.cd.detectChanges();
    });
  }

  unitListItem = [
    {
      symbol: 'Kg',
      symbolValue: 1,
      id: 1,
    },
    {
      symbol: 'gm',
      symbolValue: 500,
      id: 2,
    },
  ];
}
