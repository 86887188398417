<div class="container">
    <div class="add-review">Add Review</div>
    <form [formGroup]="reviewForm" (ngSubmit)="addNewReview()">
        <div>
            <div class="rating">
                <input type="radio" name="rating" value=5 id="star5" formControlName="rating"><label
                    for="star5">☆</label>
                <input type="radio" name="rating" value=4 id="star4" formControlName="rating"><label
                    for="star4">☆</label>
                <input type="radio" name="rating" value=3 id="star3" formControlName="rating"><label
                    for="star3">☆</label>
                <input type="radio" name="rating" value=2 id="star2" formControlName="rating"><label
                    for="star2">☆</label>
                <input type="radio" name="rating" value=1 id="star1" formControlName="rating"><label
                    for="star1">☆</label>
            </div>
        </div>
        <div class="">
            <input type="text" id="" name="review" formControlName="review" class="form-control form-fields text-dark"
                placeholder="Enter review" autocomplete="off" />
        </div>
        <div class="review-btn ">
            <button class="primary-btn purple-btn" type="submit">SUBMIT</button>
        </div>
    </form>
</div>