<div class="fwt-nutri-info">
    <div class="fwt-nutri-info-text">
        <h5>Nutrition Information</h5>
        <p class="pb-3 pt-1">Serving per 100 grams</p>
    </div>
    <div class="fwt-nutri-info-details">
    <ul *ngFor="let nutritions of productDetail.nutrition">
        <li class="d-flex align-items-center justify-content-between">
            <p>{{nutritions.title}}</p>
            <p>{{nutritions.value}}</p>
        </li>
        <!-- <li class="d-flex align-items-center justify-content-between">
            <p>Protein (g)</p>
            <p>1.35g</p>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <p>Carbohydrates (g)</p>
            <p>2.23g</p>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <p>Dietary Fibre (g)</p>
            <p>1.1g</p>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <p>Sugar (g)</p>
            <p>0.94g</p>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            <p class="pb-0">Fat (g)</p>
            <p class="pb-0">0.22g</p>
        </li> -->
    </ul>
</div>
    <p class="fwt-nutri-info-par pt-3 pb-4">The % Daily Value (DV) tells you how much a nutrient
        in a serving of food contribute to a daily diet. 2000 calories a day is used for general
        nutrition advice.</p>
    <div class="fwt-paired-note" *ngIf="productDetail.pairedWith">
        <h6>PAIRED WITH</h6>
        <p>{{productDetail.pairedWith}}</p>
    </div>
</div>
