<section class="review px-4 px-sm-2 px-lg-0">
    <div class="container">
        <div class="blog-container animation">
            <div class="row justify-content-between align-items-center gy-3">
                <div class="col-lg-4 px-0 px-sm-2">
                    <h2 class="section-title text-malachite pb-3 pb-lg-1 ">Loved By Many</h2>
                    <div class="slider-nav">
                        <div>
                            <div class="slider-thumb">
                                <div class="thumb-img position-relative">
                                    <img src="assets/images/avatar.webp" class="img-fluid" alt="">
                                    <span class="quote position-absolute end-0 bottom-0"><img
                                            src="assets/images/quote-icon.svg" alt=""></span>
                                </div>
                                <h5>Jane Doe</h5>
                            </div>
                        </div>
                        <div>
                            <div class="slider-thumb">
                                <div class="thumb-img position-relative">
                                    <img src="assets/images/avatar-2.webp" class="img-fluid" alt="">
                                    <span class="quote position-absolute end-0 bottom-0"><img
                                            src="assets/images/quote-icon.svg" alt=""></span>
                                </div>
                                <h5>Jane Doe</h5>
                            </div>
                        </div>
                        <div>
                            <div class="slider-thumb">
                                <div class="thumb-img position-relative">
                                    <img src="assets/images/avatar.webp" class="img-fluid" alt="">
                                    <span class="quote position-absolute end-0 bottom-0"><img
                                            src="assets/images/quote-icon.svg" alt=""></span>
                                </div>
                                <h5>Jane Doe</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 px-0 px-sm-2">
                    <div class="slider-slide">
                        <div>
                            <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem
                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                unknown printer took a galley of type and scrambled it to make a type specimen
                                book.
                            </p>
                            <p> It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged.</p>
                        </div>
                        <div>
                            <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem
                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                unknown printer took a galley of type and scrambled it to make a type specimen
                                book.
                            </p>
                            <p> It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged.</p>
                        </div>
                        <div>
                            <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem
                                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                unknown printer took a galley of type and scrambled it to make a type specimen
                                book.
                            </p>
                            <p> It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
