<div class="fwt-review-icon">
  <i class="fa fa-star fa-solid"  [ngClass]="{
    'remaingStar': ratingColorFlag}" *ngFor="let star of stars"></i>

  <span *ngFor="let star of getRemainingStars()">
    <i class="fa fa-star fa-regular"
       [ngClass]="{
         'remaingStar': ratingColorFlag}">
    </i>
  </span>
</div>
