import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FullPageLayoutComponent } from '../../modules/layout/full-page-layout/full-page-layout.component';

@Component({
  selector: 'app-payment-failed',
  standalone: true,
  templateUrl: './payment-failed.component.html',
  styleUrl: './payment-failed.component.scss',
  imports: [FullPageLayoutComponent],
})
export class PaymentFailedComponent {
  constructor(private router: Router) {}

  routetoHomePage() {
    this.router.navigate(['/shop']);
  }
}
