import { Component, Input, OnInit } from '@angular/core';
import { Benefits } from '../../product/model/product-details.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-aids-digestion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aids-digestion.component.html',
  styleUrl: './aids-digestion.component.scss',
})
export class AidsDigestionComponent implements OnInit {
  ngOnInit() {}
  @Input() benefits! :Benefits[];
}
