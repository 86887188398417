<section class="partner">
    <div class="container">
        <div class="title-group text-center animation aos-init" data-aos="fade-up">
            <h2 class="section-title text-green">Partners <span>and Advisors</span></h2>
        </div>
        <div class="blog-container animation aos-init" data-aos="fade-up">
            <div class="row justify-content-center gy-3">
                <div class="col-6 col-sm-4 col-lg-4 partner-img text-center">
                    <img src="assets/images/partner.png" class="img-fluid" alt="">
                </div>
                <div class="col-6 col-sm-4 col-lg-4 partner-img text-center">
                    <img src="assets/images/partner-1.png" class="img-fluid" alt="">
                </div>
                <div class="col-6 col-sm-4 col-lg-4 partner-img text-center">
                    <img src="assets/images/partner-2.png" class="img-fluid" alt="">
                </div>
                <div class="col-6 col-sm-4 col-lg-4 partner-img text-center">
                    <img src="assets/images/partner.png" class="img-fluid" alt="">
                </div>
                <div class="col-6 col-sm-4 col-lg-4 partner-img text-center">
                    <img src="assets/images/partner-2.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
