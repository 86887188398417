import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonCartComponent } from '../common-cart/common-cart.component';
import { CartItemList } from '../model/cart.model';
import { CommonModule } from '@angular/common';
import {
  UntypedFormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription, catchError, tap, throwError } from 'rxjs';
import { HttpException } from '../../../core/exceptions/http.exception';
import { AppMessages } from '../../../shared/messages';
import {
  IAppNotificationAlert,
  AppNotificationType,
} from '../../../shared/models/app-notification-alert.model';

import { ShippingAddessService } from '../address/services/shipping-address.service';
import { GetShippingAddressModel } from '../address/model/shipping-address.model';
import { TotalAmountCheckoutComponent } from '../total-amount-checkout/total-amount-checkout.component';
import { StateService } from '../address/services/state.service';
import { StateModel } from '../address/model/state.model';

@Component({
  selector: 'app-manage-user-address',
  standalone: true,
  templateUrl: './manage-user-address.component.html',
  styleUrl: './manage-user-address.component.scss',
  imports: [
    CommonCartComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TotalAmountCheckoutComponent,
  ],
})
export class ManageUserAddressComponent {
  @Output() listRedirectEvent = new EventEmitter<boolean>();
  @Input() cartDetails!: CartItemList;

  @Input() chageAddressItem!: GetShippingAddressModel;

  shippingAddressForm!: UntypedFormGroup;
  stateList!: StateModel[];
  formSubmitted: boolean = false;
  isLoading$!: Observable<boolean>;
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];
  alert: IAppNotificationAlert = {
    type: AppNotificationType.Success,
    message: '',
  };

  defaultUserAddress: any = {
    id: 0,
    fullName: '',
    locality: '',
    email: '',
    mobile: '',
    landmark: '',
    address: '',
    pincode: '',
    city: '',
    state: '',
  };
  constructor(
    private fb: FormBuilder,
    private shippingAddessService: ShippingAddessService,
    private router: Router,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.loadDocument();
    this.getStateDDList();
    // this.shippingForm();

    // AOS.init({
    //   offset: 120,
    //   once: true,
    //   duration: 1000,
    // });
  }
  getStateDDList() {
    this.stateService.getState().subscribe((resp) => {
      this.stateList = resp;
    });
  }

  loadDocument() {
    if (this.chageAddressItem) {
      this.defaultUserAddress = this.chageAddressItem;
    }
    this.shippingForm();
  }
  shippingForm() {
    this.shippingAddressForm = this.fb.group({
      fullName: [
        this.defaultUserAddress.fullName,
        [Validators.required, Validators.minLength(2)],
      ],
      address: [this.defaultUserAddress.address, [Validators.required]],
      locality: [this.defaultUserAddress.locality, [Validators.required]],
      landmark: [this.defaultUserAddress.landmark, [Validators.required]],
      pincode: [this.defaultUserAddress.pincode, [Validators.required]],
      city: [this.defaultUserAddress.city, [Validators.required]],
      state: [this.defaultUserAddress.state, [Validators.required]],
      email: [
        this.defaultUserAddress.email,
        [
          Validators.required,
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320),
          ]),
        ],
      ],
      mobile: [
        this.defaultUserAddress.mobile,
        Validators.compose([
          Validators.required,
          Validators.pattern('[789][0-9]{9}'),
        ]),
      ],
    });
  }

  onSubmit(): void {
    if (this.chageAddressItem) {
      this.edit();
    } else {
      this.formSubmitted = true;
      if (this.shippingAddressForm.valid) {
        // const formValue = Object(this.registerModel,this.shippingAddressForm.value)
        console.log('Form Submitted!', this.shippingAddressForm.value);
        this.isLoading = true;
        const sb = this.shippingAddessService
          .addShippingAddress(this.shippingAddressForm.value)
          .subscribe(
            (resp) => {
              this.isLoading = false;
              this.alert.type = AppNotificationType.Success;
              this.alert.message = AppMessages.ShippingAddressAddSuccess;
              this.listRedirectEvent.emit(true);

              // const data = 'cart3';
              // this.dataEvent.emit(data);
              // setTimeout(() => {
              //   this.router.navigate(['/shippingAddessService/verify-email'], {
              //     state: {
              //       userEmail: this.shippingAddressForm.get('email')?.value,
              //     },
              //   });
              // }, 1500);
            },
            (error) => {
              this.isLoading = false;
              this.alert.type = AppNotificationType.Error;
              if (error instanceof HttpException) {
                this.alert.message = error.message;
              } else {
                this.alert.message = AppMessages.ShippingAddressAddError;
              }
            }
          );

        this.subscriptions.push(sb);
      } else {
        console.log('Form not valid');
      }
    }
  }

  edit() {
    const formValues = this.shippingAddressForm.value;
    this.chageAddressItem = Object.assign(this.chageAddressItem, formValues);

    const sbUpdate = this.shippingAddessService
      .updateAddress(this.chageAddressItem)
      .pipe(
        tap(() => {
          this.alert.message = AppMessages.ShippingAddressAddError;
        }),
        catchError((error) => {
          if (error instanceof HttpException) {
            this.alert.message = error.message;
          } else {
            this.alert.message = AppMessages.ShippingAddressAddError;
          }
          // this.cd.detectChanges();
          return throwError(error);
        })
      )
      .subscribe((res) => {
        console.log(res);
        this.listRedirectEvent.emit(true);
        // this.router.navigate(["/userManagement"], { relativeTo: this.route });
      });

    this.subscriptions.push(sbUpdate);
  }
  isControlValid(controlName: string): boolean {
    const control = this.shippingAddressForm.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.shippingAddressForm.controls[controlName];
    return control && control.invalid && (control.dirty || control.touched);
  }
  markAsTouched(controlName: string): boolean {
    const control = this.shippingAddressForm.controls[controlName];
    control.markAsTouched();
    return this.formSubmitted;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
}
