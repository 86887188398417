<div class="faq-accordion">
    <div class="accordion fwt-accordion-shop d-none d-sm-block" id="accordionExample">
        <div class="accordion-item" *ngFor="let features of feature; let i = index">
            <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + i" aria-expanded="i == 0"
                    [attr.aria-controls]="'collapse' + i">
                    {{features.title}}
                </button>
            </h2>
            <div [id]="'collapse' + i" class="accordion-collapse collapse" 
                [class.show]="i === 0" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <p>{{features.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
