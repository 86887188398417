import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  ProductItem,
  ProductModel,
  ProductRequestModel,
} from '../../modules/product/model/product';
import { CartService } from '../../core/services/cart.service';
import { ProductService } from '../../modules/product/services/product.service';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-shop-item-list',
  standalone: true,
  imports: [CommonModule, NgSelectModule, RouterModule, FormsModule],
  templateUrl: './shop-item-list.component.html',
  styleUrl: './shop-item-list.component.scss',
})
export class ShopItemListComponent {
  @Input() productListItem!: ProductModel;
  cartUniqeID!: string;

  quantityCount: number = 0;
  listRequestModel: ProductRequestModel = {
    page: 1,
    limit: 30,
    sortBy: '',
    sortDirection: '',
    search: '',
    filter: {
      cartUniqueId: this.cartUniqeID,
      categoryId: 0,
    },
  };
  constructor(
    private cd: ChangeDetectorRef,
    private productService: ProductService,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.getUUid();
  }

  getUUid() {
    this.cartUniqeID = this.cartService.getCartUID();
    if (this.cartUniqeID) {
      this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
    } else {
      this.cartUniqeID = this.cartService.setCartUID();

      this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
      this.cd.detectChanges();
    }
  }
  updateQuantity(item: ProductItem, itemSelectedVaraiant: any) {
    const productCartValues = {
      cartUniqueId: this.cartUniqeID,
      productId: item.id,
      productVariantId: itemSelectedVaraiant.productVariantId,
      qty: 1,
    };
    this.cartService.updateCartInfo(productCartValues).subscribe((resp) => {
      console.log('Cart Updated Success');
      item.selectedVariant.cartInfo = {
        id: 0,
        cartUniqueId: this.cartUniqeID,
        productId: item.id,
        productVariantId: itemSelectedVaraiant.productVariantId,
        qty: 1,
      };
    });
  }

  addQuntity(item: ProductItem, itemSelectedVaraiant: any) {
    item.selectedVariant.cartInfo.qty++;
    const newItem = {
      cartUniqueId: this.cartUniqeID,
      productId: item.id,
      productVariantId: itemSelectedVaraiant.productVariantId,
      qty: item.selectedVariant.cartInfo.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');
    });
  }
  decreaseQuantity(item: ProductItem, itemSelectedVaraiant: any) {
    item.selectedVariant.cartInfo.qty--;
    const newItem = {
      cartUniqueId: this.cartUniqeID,
      productId: item.id,
      productVariantId: itemSelectedVaraiant.productVariantId,
      qty: item.selectedVariant.cartInfo.qty,
    };
    this.cartService.updateCartInfo(newItem).subscribe((resp) => {
      console.log('Cart Updated Success');
    });
  }
}
