import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, switchMap } from 'rxjs';
import { AppHttpClient } from '../../../core/services/app-http.service';
import { environment } from '../../../../environments/environment';
import { CategoryItem } from '../model/category';



@Injectable({
  providedIn: 'root'
})
export class CategoryService {
 
  private baseUrl = `${environment.apiUrl}/app/v1/category`;

  constructor(private http: AppHttpClient) {

  }

  // verifyActiveUser(tokenUserRequest: IGetUserByTokenRequest): Observable<IGetUserByTokenResponse> {
  //   return this.http.put<IGetUserByTokenResponse>(`${this.baseUrl}/verify-email/${tokenUserRequest.token}`,{});
  // }
  // login(Category: ILoginRequest): Observable<ILoginResponse> {
  //   return this.http.post<ILoginResponse>(`${this.baseUrl}/login`, Category);
  // }

  // registerUser(registerUser: IUserRegistrationRequest): Observable<IUserRegistrationRequest> {
  //   return this.http.post<IUserRegistrationRequest>(`${this.baseUrl}/register`, registerUser);
  // }
  // resendVerificationLink(registrationRequest: IEmailVerificationRequest): Observable<IEmailVerificationResponse> {
  //   return this.http.post<IEmailVerificationResponse>(`${this.baseUrl}/resend-verify-email`, registrationRequest);
  // }

  getCategoryDDL(){
    return this.http.get<CategoryItem[]>(this.baseUrl + '/ddl');
  }
}
