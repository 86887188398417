<app-full-page-layout>
    <main>
        <section class="fwt-team-review">
            <div class="container-xl container" *ngIf="productDetails">
                <div class="row">
                    <div class="col-lg-6 col-12 px-4 px-sm-2 pe-0 d-lg-none d-block ">
                        <h2 class="section-title mb-1 d-sm-block ">{{productDetails.name}}</h2>
                    </div>
                    <div class="col-lg-6 col-12 px-4 px-sm-2 pe-0">
                        <app-images [productImages]="productDetails"></app-images>
                    </div>

                    <div class="col-lg-6 col-12 px-4 px-sm-2 ps-xl-4">
                        <div class="fwt-shop-review">
                            <h2 class="section-title mb-1 d-sm-block d-none">{{productDetails.name}}</h2>
                            <div class="fwt-review-icon">
                                <div class="d-flex">
                                    <div> <app-star-rating [rating]="productDetails.rating"
                                            [ratingColorFlag]="true"></app-star-rating></div>

                                    <span class="ms-3">{{productDetails.ratingCount}} reviews</span>
                                </div>

                            </div>
                            <p class="mt-4">{{productDetails.longDescription}}
                            </p>
                            <div class="d-flex flex-column">
                                <div class="fwt-review-check-content order-sm-1 order-2">
                                    <div class="fwt-review-check d-flex align-items-center">

                                        <ng-container>
                                            <app-aids-digestion
                                                [benefits]="productDetails.benefits"></app-aids-digestion>
                                        </ng-container>

                                    </div>
                                </div>
                                <div class="fwt-product-details-content order-sm-2 order-1 mt-sm-0 mt-4">
                                    <div class="fwt-prodect-details d-flex align-items-center mb-lg-3 mb-0">
                                        <div class="me-4">
                                            <label for="" class="d-block">WEIGHT</label>
                                            <select name="" id="" [(ngModel)]="selectedWeight">
                                                <option class="text-dark" *ngFor="let weight of productDetails.variant"
                                                    [ngValue]="weight">{{weight.name}}
                                                    {{weight.unit.symbol}} </option>

                                            </select>
                                        </div>
                                        <div>
                                            <label for="" class="d-block">QTY</label>
                                            <div>
                                                <ng-container *ngIf="!selectedWeight?.cartInfo?.qty; else templateA">
                                                    <button type="button" class="add-to-cart-btn"
                                                        (click)="updateQuantity(selectedWeight )">ADD</button>
                                                </ng-container>

                                                <ng-template #templateA>
                                                    <div class="qty-input">
                                                        <button class="qty-count qty-count--minus" data-action="minus"
                                                            type="button"
                                                            (click)="decreaseQuantity(selectedWeight)">-</button>

                                                        <span class="product-qty">{{selectedWeight.cartInfo.qty}}</span>
                                                        <button class="qty-count qty-count--add" data-action="add"
                                                            type="button"
                                                            (click)="addQuntity(selectedWeight)">+</button>
                                                    </div>
                                                </ng-template>
                                            </div>


                                        </div>
                                    </div>
                                    <div>


                                        <h2 class="fwt-product-price">Rs.
                                            {{selectedWeight.basePrice}}</h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-sm-5 mt-0 pt-sm-5 pt-0">
                    <div class="col-lg-6 col-12 px-4 px-sm-2 ps-xl-4">
                        <ng-container>
                            <app-left-nutrients [feature]="productDetails.features"></app-left-nutrients>
                        </ng-container>
                    </div>

                    <div class="col-lg-6 ps-xl-4 px-4 px-sm-2">
                        <ng-container>
                            <app-right-nutrients [productDetail]="productDetails"></app-right-nutrients>
                        </ng-container>
                    </div>
                </div>
            </div>

        </section>

        <ng-container>
            <app-team-review [reviews]="productDetails"></app-team-review>
        </ng-container>

        <section class="A-Healthier-You-A-H healthierRectangle mt-0">
            <div class="marquee d-flex align-items-center">
                <div class="track">
                    <div class="content">&nbsp;A Healthier You, A Healthier Planet . A Healthier You, A Healthier
                        Planet . A Healthier You, A Healthier Planet . A Healthier You, A Healthier Planet . </div>
                </div>
            </div>
        </section>

        <app-products></app-products>

        <app-subscribe></app-subscribe>

        <app-partner-adviser></app-partner-adviser>
    </main>
    <script>
        $('.slider-slide').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-slide',
            dots: true,
            arrows: false,
            centerMode: true,
            focusOnSelect: true
        });
    </script>
</app-full-page-layout>