import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  GetOfferListModel,
  OfferlistResponseModel,
  CouponResponseModel,
} from '../../model/coupon-and-offer.model ';
import { ReviewsService } from '../../reviews/services/reviews.service';
import {
  AddReviewModel,
  AddReviewResponseModel,
} from '../../reviews/model/reviews.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-review',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './add-review.component.html',
  styleUrl: './add-review.component.scss',
})
export class AddReviewComponent implements OnInit {
  @Input() productId!: number;
  @Input() userFullName!: string;
  reviewForm: FormGroup;
  message: string = '';
  couponError: boolean = false;
  addReviews!: AddReviewModel;

  constructor(
    private fb: FormBuilder,
    private reviewsService: ReviewsService,
    public modal: NgbActiveModal
  ) {
    this.reviewForm = this.fb.group({
      review: ['', Validators.required],
      rating: ['', Validators.required],
    });
  }
  ngOnInit(): void {
    this.getUser();
  }
  getUser() {
    this.addReviews = {
      userFullName: this.userFullName,
      rating: 0,
      review: '',
      productId: this.productId,
    };
  }
  addNewReview() {
    if (this.reviewForm.valid) {
      this.updateAddReviewsFromForm();
      this.reviewsService.addNewReview(this.addReviews).subscribe(
        (resp: AddReviewResponseModel) => {
          if (!resp) {
            this.couponError = true;
          }
          this.modal.close();
        },
        (error) => {
          if (error.status === 400) {
            this.message = error.error?.message || 'Error saving review.';
          } else {
            this.message =
              'An error occurred while adding new review. Please try again.';
          }
          this.couponError = true;
        }
      );
    } else {
      this.message =
        'An error occurred while adding new review. Please try again.';
    }
  }

  updateAddReviewsFromForm() {
    const formValue = this.reviewForm.value;
    this.addReviews.review = formValue.review;
    this.addReviews.rating = parseInt(formValue.rating);
  }
}
