import { Component } from '@angular/core';

@Component({
  selector: 'app-partner-adviser',
  standalone: true,
  imports: [],
  templateUrl: './partner-adviser.component.html',
  styleUrl: './partner-adviser.component.scss'
})
export class PartnerAdviserComponent {

}
