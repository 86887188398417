<app-full-page-layout>
    <div class="fwt-cart-section position-relative">
        <div class="container-sm px-4 px-sm-2">
            <div class="dart-tabs d-sm-flex d-none align-items-center justify-content-center">
                <a href="javascript:void(0)" [ngClass]="{'active': activeTab === 'cart1'}"
                    (click)="activeTab = 'cart1'"><span>1</span>Cart</a>
                <a href="javascript:void(0)" [ngClass]="{'active': activeTab === 'cart2'}"
                    class="cart-line position-relative" (click)="activeTab = 'cart2'"><span>2</span>Address</a>
                <a href="javascript:void(0)"><span>3</span>Payment</a>
            </div>
            <div class="row align-items-start">
                <router-outlet>
                    <app-cart1 *ngIf="activeTab === 'cart1'" [activeTab]="activeTab"
                        (dataEvent)="handleDataFromChild($event)" (activeTabEvent)="handleActiveTab($event)"
                        [cartDetails]="carItemList" [cartUniqeID]="cartUniqeID"
                        (cartUpdate)="cartUpdateCall($event)"></app-cart1>
                    <app-cart2 (selectedAddressForPaymentEvent)="handlePaymentAddress($event)"
                        *ngIf="activeTab === 'cart2'" [cartDetails]="carItemList" [activeTab]="activeTab"
                        (activeTabEvent)="handleActiveTab($event)"></app-cart2>

                    <app-payment [userShippingAddressId]="userSelectedAddressId"
                        *ngIf="activeTab === 'cart3'"></app-payment>
                </router-outlet>

            </div>

        </div>
        <div class="fwt-cart-footer">
            <div class="container px-4 px-sm-2">
                <div class="d-md-flex d-block align-items-center justify-content-between px-0 px-md-5">
                    <div class="payment-logo">
                        <img src="assets/images/visa.png" alt="">
                        <img src="assets/images/master-card.png" alt="">
                        <img src="assets/images/rupay.png" alt="">
                        <img src="assets/images/bhim.png" alt="">
                        <img src="assets/images/cod.png" alt="">
                        <img src="assets/images/nb.png" alt="">
                    </div>
                    <div class="paymet-text">
                        <p><i class="fa-solid fa-lock"></i> 100% Secured payments</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="fwt-checkout-payment-details d-sm-none d-block">
            <div class="container-sm px-4 px-sm-2">
                <div class="d-flex align-items-center justify-content-between">
                    <div *ngIf="carItemList">
                        <p>Grand Total</p>
                        <h6>Rs. {{carItemList.totalAmount}}</h6>
                    </div>
                    <button class="primary-btn purple-btn" (click)=" checkOut()">CHECKOUT</button>
                </div>
            </div>
        </div>
    </div>
</app-full-page-layout>