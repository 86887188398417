import { Component, OnInit } from '@angular/core';
import { FullPageLayoutComponent } from '../../layout/full-page-layout/full-page-layout.component';
import { AboutUsAccordianComponent } from '../about-us-accordian/about-us-accordian.component';
import { LovedByManyComponent } from '../loved-by-many/loved-by-many.component';
import { PartnerAdviserComponent } from '../partner-adviser/partner-adviser.component';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import AOS from 'aos';
import $ from 'jquery';
@Component({
  selector: 'app-team',
  standalone: true,
  imports: [  FullPageLayoutComponent,PartnerAdviserComponent,SubscribeComponent,
    AboutUsAccordianComponent,
    LovedByManyComponent,],
  templateUrl: './team.component.html',
  styleUrl: './team.component.scss'
})
export class TeamComponent implements OnInit {
  ngOnInit(): void {
    AOS.init({
      offset: 120,
      once: true,
      duration: 1000,
    });
  }
}
