<app-full-page-layout>
    <main>
        <section class="fwt-shop-section">
            <!-- <div class="container"> -->
            <!-- <div class="container mt-5">
                    <div class="card" style="width: 18rem;">
                        <img src="https://images.unsplash.com/photo-1574226516831-e1dff420e12e" class="card-img-top" alt="Carrots">
                        <div class="card-body">
                            <h5 class="card-title">Fresh Organic Carrots</h5>
                            <p class="card-text">Our fresh organic carrots are grown without the use of harmful pesticides and fertilizers. They are sweet, crunchy, and packed with vitamins and nutrients. Perfect for snacking, salads, or cooking.</p>
                            <div class="card-footer">
                                <h6 class="card-subtitle mb-2 text-muted">$2.99 per pound</h6>
                            </div>
                        </div>
                    </div>
                </div> -->
            <div class="container-sm px-4 px-sm-2">
                <div class="fwt-shop-img">
                    <div class="row">
                        <div class="col-3 px-1 px-md-2" (click)="getProductList()">
                            <div class="products-img text-center categoryActive" [class.active]="activeCategoryId == 0">
                                <img src="assets/images/combo.png" alt="">
                                <a class="poduct-active">all</a>
                            </div>
                        </div>
                        <div class="col-3 px-1 px-md-2" *ngFor="let item of categoryListItem">
                            <div (click)="fetchProductListByID(item.id)" class="products-img text-center categoryActive"
                                [class.active]="item.id == activeCategoryId">
                                <img src="{{item.image}}" alt="" class="px-md-4 px-0">
                                <a>{{item.categoryName}}</a>
                            </div>
                        </div>
                        <!-- <div class="col-3 px-1 px-md-2">
                                <div class="products-img text-center">
                                    <img src="assets/images/lettuce-copy-3.png" alt="" class="object-fit-scale">
                                    <a href="javascript:void(0)">Vegetables</a>
                                </div>
                            </div>
                            <div class="col-3 px-1 px-md-2">
                                <div class="products-img text-center">
                                    <img src="assets/images/arugula.png" alt="">
                                    <a href="javascript:void(0)">Microgreens</a>
                                </div>
                            </div> -->
                    </div>
                </div>

                <div class="all-products-card d-md-flex d-block align-items-center justify-content-between">
                    <div>
                        <h2 class="section-title mb-1 d-sm-block">All Products</h2>
                        <p class="mt-sm-4 mt-0 mt-sm-0" *ngIf="productListItem">{{productListItem.items.length ?
                            productListItem.items.length + ' Products' :'No products available' }} </p>
                    </div>

                    <div class="all-product-select-type">
                        <select name="itemSort" id="" (change)="getListShort($event)">
                            <option *ngFor="let item of itemSort" [ngValue]="item.title">
                                {{ item.title }}
                            </option>

                        </select>
                    </div>
                </div>
                <app-shop-item-list [productListItem]="productListItem"></app-shop-item-list>
                <!-- <div class="fwt-product-card-cart">
                    <div class="row">

                        <ng-container>
                            <div class="col-6 col-lg-4 col-xl-3 px-2" *ngFor="let item of productListItem?.items">
                                <div class="fwt-product-card all-fwt-product-card mb-3">

                                    <div class="product-card-text">
                                        <div class="product-card-img" [routerLink]="'/shop/' + item.slug">
                                            <img src="{{item.thumbnail}}" alt="">
                                        </div>
                                        <h6 class="pt-2 pb-1">{{item.name}}</h6>
                                        <select name="" id="" [(ngModel)]="item.selectedVariant">
                                            <option *ngFor="let prc of item.variants" [ngValue]="prc">{{prc.name}}
                                                {{prc.unit.symbol}} </option>
                                       
                                        </select>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <p class="pb-0">Rs. {{item?.selectedVariant?.price}}</p>
                                            <ng-container *ngIf="!item.selectedVariant?.cartInfo?.qty; else templateA">
                                                <a class="add-btn"
                                                    (click)="updateQuantity(item , item.selectedVariant)">add</a>
                                            </ng-container>
                                            <ng-template #templateA>

                                                <div class="qty-input">
                                                    <button class="qty-count qty-count--minus" data-action="minus"
                                                        (click)="decreaseQuantity(item,item.selectedVariant)"
                                                        type="button">-</button>
                                                    <span
                                                        class="product-qty">{{item.selectedVariant.cartInfo.qty}}</span>
                                                    <button class="qty-count qty-count--add" data-action="add"
                                                        (click)="addQuntity(item,item.selectedVariant)"
                                                        type="button">+</button>
                                                </div>
                                            </ng-template>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div> -->
            </div>

            <!-- <section class="review">
            <div class="row cate-card">
                <div class="col-lg-12">
                    <div class="row ">
                        <div class="col-lg" *ngFor="let item of categoryListItem">
                            <div class="categoryImg">
                                <img src="{{item.image}}" class="category-img" alt="{{item.categoryName}}">
                                <div class="mt-2">
                                    <div class="category-text">{{item.categoryName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="All-Products">All Products</div>
                            <div class="productCount">{{productListItem?.items?.length}} Products</div>
                        </div>
                        <div class="col-lg-6 search-input">
                            <input type="text" placeholder="Best Selling ">
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-lg-3 mt-3" *ngFor="let productInfo of productListItem?.items">

                            <div class="card">
                                <div class="card-img-config">
                                <img class="product-img" src="{{productInfo.thumbnail}}" 
                                    alt="{{productInfo.shortDescription}}">
                                </div>
                                <div class="card-body">
                                    <h5 class="card-title">{{productInfo.shortDescription}}</h5>
                                    <div *ngIf="unitListItem">
                                        <ng-select class="form-control p-0" formControlName="unitId"
                                            placeholder="Select Weight">
                                            <ng-option *ngFor="let su of unitListItem"
                                                [value]="su.id">{{su.symbol}}</ng-option>

                                        </ng-select>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-lg-6 product-Add-left ">
                                                <div>Price </div>
                                            </div>
                                            <div class="col-lg-6 pr-0 search-input product-Add-right">
                                                <button class="btn btn-success">
                                                    ADD</button> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 -->

            <section class="A-Healthier-You-A-H healthierRectangle">
                <div class="marquee mt-8 mt-lg-8">
                    <div class="track">
                        <div class="content">&nbsp;A Healthier You, A Healthier Planet . A Healthier You, A Healthier
                            Planet . A Healthier You, A Healthier Planet . A Healthier You, A Healthier Planet .
                        </div>
                    </div>
                </div>
            </section>
            <!-- </div> -->
        </section>
        <app-subscribe></app-subscribe>
        <app-partner-adviser></app-partner-adviser>


    </main>
    <script>
        $('.slider-slide').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-slide',
            dots: true,
            arrows: false,
            centerMode: true,
            focusOnSelect: true
        });
    </script>
</app-full-page-layout>