import { Component, OnInit } from '@angular/core';
import { FullPageLayoutComponent } from '../../layout/full-page-layout/full-page-layout.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Cart1Component } from '../cart1/cart1.component';
import { Cart2Component } from '../cart2/cart2.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CommonCartComponent } from '../common-cart/common-cart.component';
import { CartService } from '../../../core/services/cart.service';
import { CartItemList, CartItemRequest } from '../model/cart.model';
import { ShippingAddessService } from '../address/services/shipping-address.service';
import { GetShippingAddressModel } from '../address/model/shipping-address.model';
import { TotalAmountCheckoutComponent } from '../total-amount-checkout/total-amount-checkout.component';
import { PaymentComponent } from '../payment/payment.component';
import { CouponAndOfferService } from '../services/coupon-and-offer.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-cart',
  standalone: true,
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
  imports: [
    FullPageLayoutComponent,
    Cart1Component,
    Cart2Component,
    RouterModule,
    CommonModule,
    CommonCartComponent,
    TotalAmountCheckoutComponent,
    PaymentComponent,
  ],
})
export class CartComponent implements OnInit {
  cartUniqeID!: string;
  returnUrl!: string;
  shippingAdressList!: GetShippingAddressModel[];
  carItemList!: CartItemList;
  offerCode: string = '';
  userSelectedAddressId!: number;
  activeTab: string = 'cart1'; // Default active tab
  // paymentFailed: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService,
    private shippingAddressService: ShippingAddessService,
    private couponAndOfferService: CouponAndOfferService,
    private auth: AuthenticationService
  ) {}
  ngOnInit(): void {
    this.getCouponCode();
    this.getUUid();
    this.getCart();
    this.getShippingAddress();
    this.getReturnUrl();
  }

  getCouponCode() {
    this.couponAndOfferService.getValue().subscribe((resp) => {
      this.offerCode = resp;
    });
  }

  getReturnUrl() {
    // this.returnUrl = this.route.snapshot.queryParams['step'];
    const step = this.route.snapshot.queryParams['step'];
    if (step === '2') {
      this.activeTab = 'cart2';
    }
  }
  getUUid() {
    this.cartUniqeID = this.cartService.getCartUID();
  }

  getCart() {
    const cartObject: CartItemRequest = {
      cartUniqueId: this.cartUniqeID,
      offerCode: this.offerCode,
    };
    this.cartService.getCart(cartObject).subscribe((resp) => {
      this.carItemList = resp;
    });
  }

  cartUpdateCall(event: boolean) {
    this.getCart();
  }

  handleDataFromChild(data: string) {
    this.activeTab = data;
  }

  // handlePaymentFailed(data: string) {
  //   this.activeTab = data;
  //   this.paymentFailed = true;
  // }
  getShippingAddress() {
    this.shippingAddressService.getShippingAddess().subscribe((resp) => {
      this.shippingAdressList = resp;
    });
  }
  handleActiveTab(data: string) {
    this.activeTab = data;
  }
  handlePaymentAddress(userAddrees: number) {
    this.userSelectedAddressId = userAddrees;
  }

  // mobileCheckOut() {
  //   if (this.activeTab == 'cart1') {
  //     this.activeTab = 'cart2';
  //   } else if ((this.activeTab = 'cart2')) {
  //     this.activeTab = 'cart3';
  //   }
  // }

  checkOut() {
    const tokenCheck = this.auth.getToken();
    if (tokenCheck) {
      if (this.activeTab == 'cart1') {
        this.activeTab = 'cart2';
      } else {
        this.activeTab = 'cart3';
      }
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          returnUrl: '/cart?step=2',
        },
      });
    }
  }
}
