import { Component, OnInit } from '@angular/core';
import { FullPageLayoutComponent } from "../../layout/full-page-layout/full-page-layout.component";
import AOS from "aos";
import { LovedByManyComponent } from '../loved-by-many/loved-by-many.component';
import { SubscribeComponent } from "../subscribe/subscribe.component";
import { PartnerAdviserComponent } from "../partner-adviser/partner-adviser.component";
@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    imports: [FullPageLayoutComponent, LovedByManyComponent, SubscribeComponent,PartnerAdviserComponent ]
})
export class HomeComponent implements OnInit {
  ngOnInit(): void {
    AOS.init({
      offset: 120,
      once: true,
      duration: 1000,
    });

    window.addEventListener('scroll', () => {
      this.handleScroll();
    });
  }

  // Function to check if an element is in the viewport
  isInViewport(element: HTMLElement) {
    var rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  // Function to handle scrolling and trigger animation
  handleScroll() {
    let boxes = document.querySelectorAll('.counter-card');
    boxes.forEach((box: any) => {
      if (this.isInViewport(box)) {
        if (!box.classList.contains('animate')) {
          box.classList.add('animate');
        }
      }
      // else {
      //     box.classList.remove('animate');
      // }
    });
  }
}
