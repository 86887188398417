import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonCartComponent } from '../common-cart/common-cart.component';
import { CartItemList } from '../model/cart.model';
import { CommonModule } from '@angular/common';
import {
  UntypedFormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription, catchError, tap, throwError } from 'rxjs';
import { HttpException } from '../../../core/exceptions/http.exception';
import { AppMessages } from '../../../shared/messages';
import {
  IAppNotificationAlert,
  AppNotificationType,
} from '../../../shared/models/app-notification-alert.model';

import { ShippingAddessService } from '../address/services/shipping-address.service';
import { GetShippingAddressModel } from '../address/model/shipping-address.model';
import { ManageUserAddressComponent } from '../manage-user-address/manage-user-address.component';
import { UserAddressListComponent } from '../user-address-list/user-address-list.component';
import { TotalAmountCheckoutComponent } from '../total-amount-checkout/total-amount-checkout.component';

@Component({
  selector: 'app-cart2',
  standalone: true,
  templateUrl: './cart2.component.html',
  styleUrl: './cart2.component.scss',
  imports: [
    CommonCartComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ManageUserAddressComponent,
    UserAddressListComponent,
    TotalAmountCheckoutComponent,
  ],
})
export class Cart2Component {
  @Input() cartDetails!: CartItemList;

  @Input() activeTab!: string;
  @Output() activeTabEvent = new EventEmitter<string>();
  @Output() selectedAddressForPaymentEvent = new EventEmitter<number>();
  userAddressCheck: boolean = true;
  shippingAdressList!: GetShippingAddressModel[];
  chageAddressItem!: GetShippingAddressModel;
  constructor(private shippingAddressService: ShippingAddessService) {}

  ngOnInit(): void {
    this.getShippingAddress();
  }
  handleChangeAddress(data: any) {
    this.chageAddressItem = data;
    this.userAddressCheck = false;
  }

  handleAddNewAddress(data: boolean) {
    this.userAddressCheck = data;
  }

  handleActiveTab(data: string) {
    this.activeTab = data;
    this.activeTabEvent.emit(this.activeTab);
  }

  getShippingAddress() {
    this.shippingAddressService.getShippingAddess().subscribe((resp) => {
      this.shippingAdressList = resp;
    });
  }

  handleRedirectFromManageUser(data: any) {
    this.userAddressCheck = data;
    this.getShippingAddress();
  }

  handleSelectedAddressFoPayement(secledtAddress: number) {
    this.selectedAddressForPaymentEvent.emit(secledtAddress);
  }
}
