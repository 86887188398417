<div class="row">
    <div class="col-lg-8 pe-4">
        <div class="fwt-cart-card d-flex align-items-center justify-content-between mb-4" *ngIf="cartDetails">
            <div>
                <h2 class="section-title mb-1 d-sm-block">Shopping Cart</h2>
                <p class="mt-4 mt-sm-0">{{cartDetails.items.length}} Products</p>
            </div>
            <div>
                <p class="step-1 d-sm-none d-block">Step 1/3</p>
                <h6 class="mt-4 mt-sm-0">Total Rs. {{cartDetails.totalAmount}}</h6>
            </div>
        </div>
    </div>
</div>
<!-- <div>
    <div class="d-flex cart-coupons-details mb-3 justify-content-between" *ngIf="paymentFailed">
        <div class=" d-flex justify-content-between">
            <div><img src="assets/media/svg/error.svg" alt=""></div>
            <div class="px-2">
                <div class="payment-failed">Payment Failed</div>
                <div class="payment-for-this">Your payment for this order could not be completed. Please retry
                    the payment. </div>
            </div>

        </div>


        <div class="">
            <div class="RETRY-PAYMENT">RETRY PAYMENT</div>
        </div>
    </div>
</div> -->
<div class="row align-items-start" *ngIf="cartDetails">
    <div class="col-lg-8 pe-xl-4 pe-3">

        <div class="cart-card pb-0 mb-3" *ngFor="let cartItems of cartDetails?.items">
            <div class="d-flex align-items-md-center align-items-start position-relative p-2 p-sm-3">
                <div class="cart-remove-btn">
                    <a (click)="updateQuantityZero(cartItems)">REMOVE</a>
                </div>
                <div>
                    <img src="{{cartItems.thumbnail}}" alt="">
                </div>
                <div class=" ms-2 ms-sm-3 w-100">
                    <h6>{{cartItems.productName}}</h6>
                    <p>Rs. {{cartItems.itemTotal}}</p>
                    <div class="fwt-cart-product-gms d-md-flex align-items-center">
                        <select [(ngModel)]="cartItems.productVariantId">
                            <option *ngFor="let varient of cartItems.varients" [ngValue]="varient.id">{{varient.name}}
                                {{varient?.unit?.symbol}}</option>

                        </select>

                        <div class="qty-input mt-md-0 mt-2">

                            <!-- <div class="qty-input"> -->
                            <button class="qty-count qty-count--minus" data-action="minus"
                                (click)="decreaseQuantity(cartItems)" type="button">-</button>
                            <span class="product-qty">{{cartItems.qty}}</span>
                            <button class="qty-count qty-count--add" data-action="add" (click)="addQuntity(cartItems)"
                                type="button">+</button>
                            <!-- </div> -->

                        </div>
                    </div>
                </div>
            </div>
            <div class="remove-cart-set py-2">
                <p class="text-center">REMOVE FROM CART</p>
            </div>
        </div>


    </div>
    <div class="col-lg-4 ps-xl-4 ps-3 mt-lg-0 mt-4">
        <app-total-amount-checkout [cartDetails]="cartDetails"
            (activeTabEvent)="handleActiveTab($event)"></app-total-amount-checkout>
    </div>

</div>