import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MyOrdersService } from '../my-account/services/my-orders.service';
import {
  PlaceMyOrderRequestModel,
  PlaceMyOrderResponseModel,
} from '../address/model/my-orders.model';
import { CartService } from '../../../core/services/cart.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { CouponAndOfferService } from '../services/coupon-and-offer.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
declare var Razorpay: any;

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
})
export class PaymentComponent implements OnInit {
  emailId!: string;
  userFullName!: string;
  mobileNumber!: string;
  cartUniqeId!: string;
  offerCode: string = '';
  placeMyOrder!: PlaceMyOrderResponseModel;
  @Input() userShippingAddressId!: number;

  constructor(
    private myOrderService: MyOrdersService,
    private cartService: CartService,
    public router: Router,
    private couponAndOfferService: CouponAndOfferService,
    private authenticationService: AuthenticationService
  ) {}
  ngOnInit(): void {
    this.getUserMailId();
    this.getCouponCode();
    this.getCartUniqeId();
    this.getPlaceOrderDetailsByID();
  }
  getCouponCode() {
    this.couponAndOfferService.getValue().subscribe((resp) => {
      this.offerCode = resp;
    });
  }
  getPlaceOrderDetailsByID() {
    const orderModel: PlaceMyOrderRequestModel = {
      cartUniqueId: this.cartUniqeId,
      addressId: this.userShippingAddressId,
      offerCode: this.offerCode,
    };
    this.myOrderService.placeMyOrder(orderModel).subscribe((resp) => {
      this.placeMyOrder = resp;
      this.payNow(this.placeMyOrder.paymentReferenceId);
    });
  }
  getCartUniqeId() {
    this.cartUniqeId = this.cartService.getCartUID();
  }

  getUserMailId() {
    this.mobileNumber =
      this.authenticationService.getUserMobileTOLocalStorage();
    this.emailId = this.authenticationService.getUserEmailIdTOLocalStorage();
    this.userFullName =
      this.authenticationService.getUserFullNameTOLocalStorage();
  }

  // razorpay call method

  payNow(order_id: string) {
    const razorpayOptions = {
      key: `${environment.razorPayKey}`,
      order_id: order_id,
      name: 'GoPurple',
      description: 'go-purple-payment',
      image: '../../assets/media/logo/logo.svg',
      handler: (response: any) => {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_subscription_id);
        // alert(response.razorpay_signature);

        if (response.razorpay_payment_id) {
          this.cartService.deleteCartUID();
          this.router.navigate(['/my-account/my-orders']);
        } else {
          this.router.navigate(['/payment-failed']);
        }
      },
      prefill: {
        name: this.userFullName,
        email: this.emailId,
        contact: this.mobileNumber,
      },
      notes: {},
      theme: {
        color: '#007bff',
      },
      modal: {
        ondismiss: () => {
          console.log('Dismissed');
        },
      },
    };

    const rzp1 = new Razorpay(razorpayOptions);
    rzp1.open();
    rzp1.on('payment.failed', (response: any) => {
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      // this.toastrService.showErrorToast(response.error.reason);
    });
  }
}
