<section class="fwt-verified-reviews position-relative">
    <div class="container-xl container px-4 px-sm-2">
        <div class="mx-sm-0">
            <div class="fwt-verified-bt d-sm-flex align-items-lg-center align-items-start justify-content-between pb-4">
                <div class="fwt-verified-content mb-sm-0 mb-4" *ngIf="reviews">
                    <h2 class="section-title text-green mb-3">Verified Reviews</h2>
                    <div class="fwt-review-icon mb-2">
                        <!-- <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i> -->
                        <app-star-rating [rating]="reviews.rating"></app-star-rating>
                    </div>
                    <h5>{{reviews.rating}} out of 5 stars</h5>
                    <p>Based on {{reviews.ratingCount}} reviews</p>
                </div>
                <div class="fwt-verified-btn" *ngIf="tokenAvaialableCheck">
                    <button class="primary-btn purple-btn" (click)="addReview(reviews.id)">WRITE A REVIEW</button>
                </div>
            </div>

            <!-- <div
                class="fwt-verified-bt d-flex align-items-lg-center align-items-start justify-content-between pb-4 mt-4">
                <div class="fwt-verified-content">
                    <h4 class="text-green mb-1">Pranav Patel</h4>
                    <div class="fwt-review-icon mb-3">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                    </div>
                    <p>Fresh updates on product launches, exclusive news, recipes, and farm-to-table secrets</p>
                </div>
                <div class="fwt-verified-date d-none d-sm-block">
                    <h5>28/09/2023</h5>
                </div>
            </div> -->
            <div *ngFor="let userReviews of reviewsList?.items">


                <div class="fwt-verified-bt d-flex align-items-center justify-content-between pb-4 mt-4">
                    <div class="fwt-verified-content">
                        <h4 class="text-green mb-1">{{userReviews.userFullName}}</h4>
                        <div class="fwt-review-icon">
                            <app-star-rating [rating]="userReviews.rating"></app-star-rating>
                        </div>
                        <p>{{userReviews.review}}</p>
                    </div>
                    <div class="fwt-verified-date d-none d-sm-block">
                        <h5>{{userReviews.createdAt | date:'dd/MM/YYYY'}}</h5>
                    </div>
                </div>
            </div>

            <!-- <div
                class="fwt-verified-bt d-flex align-items-lg-center align-items-start justify-content-between pb-4 mt-4">
                <div class="fwt-verified-content">
                    <h4 class="text-green mb-1">Preeti Agrawal</h4>
                    <div class="fwt-review-icon mb-3">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                    </div>
                    <p>Fresh updates on product launches, exclusive news, recipes, and farm-to-table secrets</p>
                </div>
                <div class="fwt-verified-date d-none d-sm-block">
                    <h5>28/09/2023</h5>
                </div>
            </div> -->

            <!-- <div
                class="fwt-verified-bt d-flex align-items-lg-center align-items-start align-items-start justify-content-between pb-4 mt-4 border-0">
                <div class="fwt-verified-content">
                    <h4 class="text-green mb-1">Anushka Malhotra</h4>
                    <div class="fwt-review-icon mb-3">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                    <p>Fresh updates on product launches, exclusive news, recipes, and farm-to-table secrets</p>
                </div>
                <div class="fwt-verified-date d-none d-sm-block">
                    <h5>28/09/2023</h5>
                </div>
            </div> -->

        </div>

        <!-- <div class="fwt-shop-pagination d-sm-block d-none">
            <ul class="d-flex align-items-center justify-content-center">
                <li class="pe-sm-5 pe-2"><i class="fa-solid fa-chevron-left"></i></li>
                <li>
                    <p>1</p>
                </li>
                <li>
                    <p>2</p>
                </li>
                <li>
                    <p>3</p>
                </li>
                <li>
                    <p>4</p>
                </li>
                <li>
                    <p>5</p>
                </li>
                <li class="ps-sm-5 pe-2"><i class="fa-solid fa-chevron-right"></i></li>
            </ul>
        </div> -->
        <div *ngIf="reviewsList?.items">
            <app-paginantor [totalPages]="totalPages" (pageChange)="onPageChange($event)"></app-paginantor>
        </div>

        <div class="fwt-load-more-btn text-center d-sm-none d-block mt-5" *ngIf="mobileReviewList && reviewsList">
            <a (click)="reviewListLoadMore()" *ngIf="mobileReviewList.items.length < reviewsList.total">LOAD MORE</a>

        </div>
    </div>
</section>