import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ShopItemListComponent } from '../../../shared/shop-item-list/shop-item-list.component';
import { ProductModel, ProductRequestModel } from '../../product/model/product';
import { CartService } from '../../../core/services/cart.service';
import { CategoryService } from '../../category/services/category.service';
import { ProductService } from '../../product/services/product.service';

@Component({
  selector: 'app-products',
  standalone: true,
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
  imports: [ShopItemListComponent],
})
export class ProductsComponent implements OnInit {
  cartUniqeID!: string;
  productListItem!: ProductModel;
  listRequestModel: ProductRequestModel = {
    page: 1,
    limit: 30,
    sortBy: '',
    sortDirection: '',
    search: '',
    filter: {
      cartUniqueId: this.cartUniqeID,
      categoryId: 0,
    },
  };
  constructor(
    private categoryService: CategoryService,
    private cd: ChangeDetectorRef,
    private productService: ProductService,
    private cartService: CartService
  ) {}
  ngOnInit() {
    this.getUUid();
    this.fetchProductListByID();
  }

  getUUid() {
    this.cartUniqeID = this.cartService.getCartUID();
    if (this.cartUniqeID) {
      this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
    } else {
      this.cartUniqeID = this.cartService.setCartUID();
      // this.cartUniqeID = categoryUuid;
      this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
      // this.cd.detectChanges();
    }
  }

  fetchProductListByID() {
    // this.listRequestModel.filter.categoryId = ;
    // this.listRequestModel.filter.cartUniqueId = this.cartUniqeID;
    this.productService.all(this.listRequestModel).subscribe((resp) => {
      this.productListItem = resp;
      this.productListItem.items = this.productListItem.items.map((item) => {
        item.selectedVariant = item.variants[0];
        return item;
      });
      // this.listRequestModel.filter.categoryId = 0;
    });
  }
}
